import { useDispatch, useSelector } from "react-redux";
import { setNewAccountInfo } from "../../../../store/slice/userAccountSlice";
import { Col, Form, Row } from "antd";
import ThemeInputNumber from "../../../../components/ThemeInputNumber/ThemeInputNumber";
import AccountDetailsRadioView from "../AccountDetailsRadioView";
import { AccountDetails, Main } from "../style";
import { compareObj } from "../../../../Helper/constant";

import ThemeInput from "../../../../components/ThemeInput";
import AdditionalInfoView from "./AdditionalInfoView";
import AccountOpeningView from "./AccountOpeningView";
import React from "react";

const RoleWiseView = ({ is_edits, parentForm }) => {
  const dispatch = useDispatch();
  const Partnership = Form.useWatch("partnershipWith", parentForm);
  const minimumBrokerage = Form.useWatch("minimumBrokerageRequire", parentForm);
  const m2m = Form.useWatch("isM2MAlert", parentForm);

  const market_access = useSelector(
    (state) => state.userAccountSlice.market_access
  );
  //redux
  const accountDetails = useSelector(
    (state) => state.userAccountSlice.accountDetails
  );
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const loginDetail = useSelector(
    (state) => state.authSlice?.userDetail?.partnershipWith
  );

  const isRent = loginDetail === "rent";
  const parentDetails = useSelector(
    (state) =>
      state.authSlice?.MarketAccessDetails?.accountDetails
        ?.minimumBrokerageRequire
  );
  const parentValidationDetails = useSelector((state) => state.authSlice);

  const parentBrokerage =
    parentValidationDetails?.MarketAccessDetails?.accountDetails
      ?.minimumBrokerageRequire;
  const isMaster = accountType === "masterAdmin";
  const isparentRent =
    parentValidationDetails.userDetail.partnershipWith === "rent";

  //functions
  const onChange = (e) => {
    const { name, value } = e.target;
    const ispartnerShip = name === "partnershipWith";
    const isBrokerage = name === "minimumBrokerageRequire";

    if (
      (ispartnerShip && !isMaster && !isRent) ||
      (isBrokerage && !isMaster && parentDetails & !isRent) ||
      (is_edits && ispartnerShip) ||
      (is_edits && isBrokerage)
    ) {
      return;
    }

    dispatch(
      setNewAccountInfo({
        parent_key: "accountDetails",
        key: name,
        value: value,
      })
    );

    // set minimum Brokerage required true
    if (ispartnerShip && value === "brokerage") {
      dispatch(
        setNewAccountInfo({
          parent_key: "accountDetails",
          key: "minimumBrokerageRequire",
          value: true,
        })
      );
    }
  };

  const handlePartnerShip = (e) => {
    const isChange = e.target.value === "brokerage";
    if (isChange) {
      for (let i = 0; i < market_access?.length; i++) {
        parentForm.setFieldValue([`percentage$${market_access[i]}`], 0);
      }
    }
  };

  const handleMinimumBrokerage = (e) => {
    if (e.target.value === "false") {
      const changeArray = [];
      for (let i = 0; i < market_access?.length; i++) {
        let delivery = `deliveryTotal$${market_access[i]}`;
        let intraday = `intradayTotal$${market_access[i]}`;
        changeArray.push(delivery);
        changeArray.push(intraday);
      }
      changeArray.forEach((el) => {
        parentForm.setFieldValue([el], 0);
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "false") {
      compareObj[name]?.forEach((el) => {
        // parentForm.setFieldValue(el, 0);
        parentForm.setFields([
          {
            name: el,
            value: null,
            touched: true,
          },
        ]);
      });
    }
  };

  return (
    <>
      <Main>
        <AccountDetails>
          <Row gutter={[10, 10]} align={"left"}>
            <Col xs={24} sm={16} md={14} style={{ alignContent: "center" }}>
              <AccountDetailsRadioView
                title={"M2M Alert"}
                name="isM2MAlert"
                formProps={{
                  name: "isM2MAlert",
                  onChange: handleChange,
                  initialValue: false,
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <ThemeInputNumber
                title="Profit"
                extraProps={{
                  input_type: "number",
                  parent_key: "accountDetails",
                  nested_key: "M2MAlert",
                }}
                inputProps={{
                  suffix: "%",
                  placeholder: "",
                  width: "95%",
                  name: "M2MAlert$profit",
                  disabled: !m2m,
                }}
                marginBottom={"0px !important"}
                titleWrapperStyle={{ justifyContent: "center" }}
                formProps={{
                  name: "M2MAlert$profit",
                  dependencies: [`isM2MAlert`, "M2MAlert$loss"],
                  rules: [
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        let lossValue = getFieldValue("M2MAlert$loss");
                        if (
                          (getFieldValue("isM2MAlert") &&
                            value >= 0 &&
                            value <= 100 &&
                            (value || value === 0)) ||
                          (!value &&
                            lossValue >= 0 &&
                            lossValue <= 100 &&
                            (lossValue || lossValue === 0)) ||
                          !getFieldValue("isM2MAlert")
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(`Only allow between 0 to 100`)
                        );
                      },
                    }),
                  ],
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
              <ThemeInputNumber
                title="Loss"
                extraProps={{
                  input_type: "number",
                  parent_key: "accountDetails",
                  nested_key: "M2MAlert",
                }}
                inputProps={{
                  suffix: "%",
                  placeholder: "",
                  width: "95%",
                  name: "M2MAlert$loss",
                  disabled: !m2m,
                }}
                marginBottom={"0px !important"}
                titleWrapperStyle={{ justifyContent: "center" }}
                formProps={{
                  name: "M2MAlert$loss",
                  dependencies: [`isM2MAlert`, "M2MAlert$profit"],
                  rules: [
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        let profiltValue = getFieldValue("M2MAlert$profit");

                        if (
                          (getFieldValue("isM2MAlert") &&
                            value >= 0 &&
                            value <= 100 &&
                            (value || value == 0)) ||
                          (!value &&
                            profiltValue >= 0 &&
                            profiltValue <= 100 &&
                            (profiltValue || profiltValue == 0)) ||
                          !getFieldValue("isM2MAlert")
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(`Only allow between 0 to 100`)
                        );
                      },
                    }),
                  ],
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={16} md={14}>
              <AccountDetailsRadioView
                title={"Only Position Squareup"}
                name="isOnlyPositionSquareup"
                formProps={{
                  name: "isOnlyPositionSquareup",
                  initialValue: false,
                }}
              />
            </Col>
            <Col xl={10} lg={0} md={0}></Col>
          </Row>

          <Row>
            <Col xs={24} sm={16} md={14} style={{ marginTop: "1rem" }}>
              <AccountDetailsRadioView
                title={"Partnership with"}
                onChange={handlePartnerShip}
                name="partnershipWith"
                required
                disabled={(!isMaster && !isparentRent) || is_edits}
                options={[
                  {
                    value: "percentage",
                    label: "%",
                  },
                  {
                    value: "brokerage",
                    label: "Br",
                  },
                ]}
                formProps={{
                  name: "partnershipWith",
                  initialValue: parentForm?.getFieldValue("partnershipWith")
                    ? parentForm?.getFieldValue("partnershipWith")
                    : "percentage",
                }}
              />
            </Col>
            <Col xl={10} lg={0} md={0}></Col>
          </Row>

          {Partnership !== "brokerage" && (
            <Row>
              <Col xs={24} sm={16} md={14} style={{ marginTop: "1rem" }}>
                <AccountDetailsRadioView
                  title={"Minimum Brokerage Required"}
                  name="minimumBrokerageRequire"
                  formProps={{
                    name: "minimumBrokerageRequire",
                    onChange: handleMinimumBrokerage,
                    initialValue: true,
                  }}
                  disabled={
                    (!isMaster && !isparentRent && parentBrokerage) || is_edits
                  }
                />
              </Col>
              <Col xl={10} lg={0}></Col>
            </Row>
          )}
        </AccountDetails>
        <AccountOpeningView is_edits={is_edits} />
      </Main>
      <Row style={{ marginTop: "1.5rem" }}>
        <Col span={24} align="center">
          <AdditionalInfoView parentForm={parentForm} />
        </Col>
        <Col span={24} align="center">
          <ThemeInput
            title={"Remark"}
            inputProps={{
              placeholder: "",
              name: "remark",
            }}
            formProps={{
              name: "remark",
            }}
          />
        </Col>
      </Row>
    </>
  );
};
export default React.memo(RoleWiseView);

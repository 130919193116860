import {
  createContext,
  useContext,
  useRef,
  useState,
  useDeferredValue,
  useEffect,
} from "react";
import { produce } from "immer";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import {
  resetWebSocket,
  setBatchUpdate,
  setSocketFlag,
  setWebSocket,
} from "../../store/slice/webSocketSlice";
import { addPositionInMarketWatch } from "../../store/slice/marketWatchSlice";
import { setBroadCastExpiry, setM2mAlert } from "../../store/slice/globleSlice";
import {
  setBatchPositionUpdate,
  setPositionSocketData,
} from "../../store/slice/positionSocketSlice";
import store from "../../store/store";

const io = require("socket.io-client");

const SocketContext = createContext();

const watchTime = (timestamps) => {
  const validTimestamps = timestamps.filter(
    (timestamp) => !isNaN(new Date(timestamp).getTime())
  );
  const mostRecentTime = new Date(
    Math.max(...validTimestamps?.map((timestamp) => new Date(timestamp)))
  );

  const year = mostRecentTime.getFullYear();
  const month = String(mostRecentTime.getMonth() + 1).padStart(2, "0");
  const day = String(mostRecentTime.getDate()).padStart(2, "0");
  const hours = String(mostRecentTime.getHours()).padStart(2, "0");
  const minutes = String(mostRecentTime.getMinutes()).padStart(2, "0");
  const seconds = String(mostRecentTime.getSeconds()).padStart(2, "0");

  const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return isoDateString;
};

export const useSocket = () => {
  return useContext(SocketContext);
};

const SocketProvider = ({ children }) => {
  const isConnected = useRef(null);
  const websocket = useRef(null);
  const data = useRef({});
  const positionRef = useRef(null);
  const queueRef = useRef([]);
  const positionQueueRef = useRef([]);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  //state
  const [socket, setSocket] = useState(false);
  const [_socketData, setSocketData] = useState({});
  const socketData = useDeferredValue(_socketData);
  const [tradeData, setTradeData] = useState(null);

  //redux
  const token = useSelector((state) => state.authSlice.accessToken);

  const positionSocket = useSelector(
    (state) => state.positionSocketSlice?.socketConnect
  );

  const doSend = (obj) => {
    if (obj?.event) {
      websocket.current?.emit(obj?.event, obj?.payload);
    }
  };

  const doSendPosition = (obj) => {
    if (obj?.event) {
      positionRef.current?.emit(obj?.event, obj?.payload);
    }
  };

  const closeSocket = () => {
    data.current = {};
    websocket.current?.close();
    isConnected.current = false;
    setSocket(false);
  };

  const connect = () => {
    if (websocket.current != null) {
      websocket.current = null;
    }

    websocket.current = io(`${process.env.REACT_APP_API_URL}marketwatch`, {
      auth: {
        token: token,
      },
      reconnect: true,
      transports: ["websocket"],
    });

    websocket.current?.on("connect", () => {
      console.log(":::::::::: SOCKET CONNECTED ::::::::::");
      isConnected.current = true;
      setSocket(true);
      dispatch(resetWebSocket());
      dispatch(setSocketFlag(true));
      const callScript = store.getState()?.globleSlice?.scriptCallList;
      setTimeout(() => {
        doSend({
          event: "subscribeToServerMarket",
          payload:
            callScript && callScript?.length > 0
              ? [...callScript, "256265", "265", "291849", "260105"]
              : ["256265", "265", "291849", "260105"],
        });
      }, 500);
    });

    websocket.current?.on("smMarketData", (_data) => {
      let decoder = new TextDecoder();

      _data = decoder.decode(_data);
      _data = JSON.parse(_data);
      let liveData = _data?.data;
      let newData = {};
      if (liveData?.Symbol) {
        newData.Symbol = liveData?.Symbol;
        if (_data?.name === "touchline") {
          newData.Spread = liveData?.Spread;
          newData.Price_Change = liveData?.Price_Change;
          newData.Net_Change = liveData?.Net_Change;
          newData.LTP = liveData?.LTP;
          newData.Ask = liveData?.Bid;
          newData.Bid = liveData?.Ask;
          newData.High = liveData?.High;
          newData.Low = liveData?.Low;
          newData.Open = liveData?.Open;
          newData.Close = liveData?.Prev_Close;
          newData.Volume = liveData?.Volume;
          const timestamps = [liveData?.lastupdated_time];
          let isoDateString = watchTime(timestamps);
          newData.LUT = isoDateString;
        } else if (_data?.name === "tick") {
          newData.LTP = liveData?.LTP;
          newData.High = liveData?.High;
          newData.Low = liveData?.Low;
          newData.Open = liveData?.Open;
          newData.Close = liveData?.Prev_Close;
          newData.Volume = liveData?.Volume;
          newData.LUT = liveData?.Timestamp;
          if (liveData?.Ask !== 0) {
            newData.Ask = liveData?.Bid;
          }
          if (liveData?.Bid !== 0) {
            newData.Bid = liveData?.Ask;
          }
        }

        queueRef.current.push(newData);

        // dispatch(setWebSocket({ id: liveData?.Symbol, data: newData }));
      }

      // if (liveData?.Symbol) {
      //   const updatedData = produce(data.current, (draft) => {
      //     const symbolData = draft[liveData.Symbol] || {};
      //     if (_data?.name === "touchline") {
      //       symbolData.Spread = liveData?.Spread;
      //       symbolData.Price_Change = liveData?.Price_Change;
      //       symbolData.Net_Change = liveData?.Net_Change;
      //       symbolData.LTP = liveData?.LTP;

      //       // if (_data?.data[0]?.Ask == -20000000) {
      //       //   symbolData.Ask = _data?.data[0]?.LTP;
      //       //   symbolData.Bid = _data?.data[0]?.LTP;
      //       // } else {

      //       symbolData.Ask = liveData?.Bid;
      //       symbolData.Bid = liveData?.Ask;
      //       // }

      //       symbolData.High = liveData?.High;
      //       symbolData.Low = liveData?.Low;
      //       symbolData.Open = liveData?.Open;
      //       symbolData.Close = liveData?.Prev_Close;
      //       // symbolData.ATP = _data?.data[0]?.ATP;
      //       symbolData.Volume = liveData?.Volume;

      //       const timestamps = [
      //         // _data?.data[0]?.LastUpdateTime,
      //         // _data?.data[0]?.tick_timestamp,
      //         liveData?.lastupdated_time,
      //         // _data?.data[0]?.bid_timestamp,
      //       ];
      //       let isoDateString = watchTime(timestamps);
      //       symbolData.LUT = isoDateString;
      //     }
      //     // else if (_data?.name === "bidask") {
      //     //   symbolData.Ask = _data?.data?.Bid;
      //     //   symbolData.Bid = _data?.data?.Ask;
      //     //   symbolData.LUT = _data?.data?.Time;
      //     // }
      //     else if (_data?.name === "tick") {
      //       symbolData.LTP = liveData?.LTP;
      //       symbolData.High = liveData?.High;
      //       symbolData.Low = liveData?.Low;
      //       symbolData.Open = liveData?.Open;
      //       symbolData.Close = liveData?.Prev_Close;
      //       symbolData.Volume = liveData?.Volume;
      //       symbolData.LUT = liveData?.Timestamp;

      //       // if (_data?.data?.Symbol !== "SENSEX") {
      //       //   console.log("tickTime", _data?.data);
      //       // }
      //       if (data?.Ask !== 0) {
      //         symbolData.Ask = liveData?.Bid;
      //       }
      //       if (data?.Bid !== 0) {
      //         symbolData.Bid = liveData?.Ask;
      //       }
      //     }

      //     if (_data.name == "touchline") {
      //       draft[liveData?.Symbol] = symbolData;
      //     } else {
      //       draft[liveData?.Symbol] = symbolData;
      //     }
      //   });

      //   data.current = updatedData;
      //   dispatch(setWebSocket(updatedData));
      //   // setSocketData(updatedData);
      // }
    });

    websocket.current?.on("tradeCreated", (trade) => {});

    websocket.current?.on("positionList", (data) => {});

    websocket.current?.on("broadCastExpiryPosition", (data) => {
      dispatch(setBroadCastExpiry(data));
    });

    websocket.current?.on("createUser", (data) => {
      console.log("createUser");
      queryClient.refetchQueries(["usersFilter"]);
    });

    websocket.current?.on("m2mAlert", (data) => {
      dispatch(setM2mAlert(data));
      // setTradeData(trade);
    });
    let callApi = true;
    websocket.current?.on("updatedPosition", (data) => {
      console.log("upadatedPosition in socket", data);
      if (!callApi) return;
      callApi = false;
      const pathname = window?.location?.pathname;

      if (pathname === "/dashboard") {
        queryClient.refetchQueries({ queryKey: "allTrade", type: "active" });
        queryClient.refetchQueries({
          queryKey: "pendingTrade",
          type: "active",
        });
      } else if (pathname === "/trading/position") {
        queryClient.refetchQueries({
          queryKey: "getPositionData",
          type: "active",
        });
      }
      setTimeout(
        () => {
          callApi = true;
        },
        data?.status === "Close" ? 500 : 1000
      );

      // dispatch(
      //   addPositionInMarketWatch({ key: data?.marketName, payload: data })
      // );
    });

    websocket.current?.on("ping", function (data) {
      // socket.emit("pong", { beat: 1 });
      // doSend({
      //   event: "ping",
      //   payload: { beat: 1 },
      // });
    });

    websocket.current?.on("disconnect", (reason) => {
      console.log(":::::::::: SOCKET DISCONNECTED ::::::::::");
      console.log("Disconnected for reason:", reason);

      // Reconnect if the reason is a ping timeout
      if (reason === "ping timeout") {
        // dispatch(setAuth(null));
      }

      setSocket(false);
      dispatch(resetWebSocket());
      // dispatch(setSocketFlag(false));
      isConnected.current = false;
    });
  };

  const positionConnect = () => {
    if (positionRef.current !== null) {
      positionRef.current = null;
    }
    positionRef.current = io(`${process.env.REACT_APP_API_URL}position`, {
      auth: {
        token: token,
      },
      reconnect: true,
      transports: ["websocket"],
    });
    positionRef.current.on("connect", () => {
      console.log("::::::::::POSITION SOCKET CONNECTED::::::::::");
    });
    positionRef.current.on("getPositions", (data) => {
      // console.log("getPositionData", data);
      positionQueueRef.current.push(data);
      // dispatch(setPositionSocketData({ id: data?._id, data: data }));
    });
    positionRef.current?.on("disconnect", (reason) => {
      console.log("::::::::::POSITION SOCKET DISCONNECTED ::::::::::");
    });
  };
  const positinDisconnect = () => {
    positionRef.current?.close();
  };

  // useLayoutEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden) {
  //       console.log("CONNECTING SOCKET");
  //       connect();
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  useEffect(() => {
    let updateSlice;
    if (token) {
      connect();
      updateSlice = setInterval(() => {
        if (queueRef.current?.length > 0) {
          dispatch(setBatchUpdate(queueRef.current));
          queueRef.current = [];
        }
      }, 100);
    }
    return () => {
      closeSocket();
      clearInterval(updateSlice);
    };
  }, [token]);

  useEffect(() => {
    let updatePosition;
    if (token && positionSocket) {
      positionConnect();
      updatePosition = setInterval(() => {
        if (positionQueueRef.current?.length > 0) {
          dispatch(setBatchPositionUpdate(positionQueueRef.current));
          positionQueueRef.current = [];
        }
      }, 300);
    }

    return () => {
      positinDisconnect();
      clearInterval(updatePosition);
    };
  }, [positionSocket]);

  return (
    <SocketContext.Provider
      value={{
        socketData,
        socket,
        doSend,
        closeSocket,
        doSendPosition,
        tradeData,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;

import React from "react";
import ThemeCard from "../../components/ThemeCard";
import {
  Col,
  Pagination,
  Popconfirm,
  Row,
  Tooltip,
  notification,
  theme,
} from "antd";
import ThemeDropDown from "../../components/ThemeDropDown";
import { options } from "../../Helper/constant";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import MarketScriptList from "../../components/MarketScriptList/MarketScriptList";
import { saveAs } from "file-saver";
import {
  findMarket,
  formatNumber,
} from "../../components/FormateToINR/formatToINR";
import { limitNumberLength } from "../../components/SocketLiveData";
import GetDate from "../../components/DatePicker/GetDate";
import { ActiveDot, buttonTitles, CSVColumns } from "./style";
import dayjs from "dayjs";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import EditTrade from "../../components/EditTrade/EditTrade";
import VerificationModal from "../../components/VerificationModal/VerificationModal";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { StyledButton } from "../../styles/commonStyle";
import { DeleteIcon, EditIcon } from "../Dashboard/style";

const convertToCSV = (data, TradeColumns) => {
  console.log({ TradeColumns, data });
  const header = TradeColumns?.map((col) => col.title).join(",");
  const rows = data?.map((row, index) => {
    return TradeColumns?.map((col) => {
      let value;
      if (col.dataIndex === "srNo") {
        value = index + 1; // Handle SR NO. separately as it is index based
      } else {
        value = row[col.dataIndex];
        if (col.dataIndex === "customer") {
          value = `${row?.customer?.[0]?.userId} (${row?.customer?.[0]?.accountName})`;
        } else if (col.dataIndex === "valan") {
          value = value?.valanName || "";
        } else {
          value = value !== null && value !== undefined ? value : "";
        }
      }
      return value;
    }).join(",");
  });
  console.log({ rows });
  return [header, ...rows].join("\n");
};

function TradingTrade() {
  // state
  const [value, setValue] = useState({
    title: null,
  });
  const [showModal, setShowModal] = useState({
    flag: false,
    name: null,
    record: null,
  });
  const [totalCounts, setTotalCounts] = useState(0);

  const valueRef = useRef({});
  const dispatch = useDispatch();
  const { params, setSearchParams } = useGetQueryParams();

  //redux
  const LoginDetails = useSelector((state) => state.authSlice?.userDetail);
  const additional = useSelector(
    (state) => state?.authSlice?.additionalSharing
  );
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);
  const loginUserId = LoginDetails?.userId;
  const loginAccountType = LoginDetails?.accountType;
  const isOwner = loginAccountType === "owner";
  const isAllow =
    loginAccountType !== "customer" && loginAccountType !== "broker";

  useEffect(() => {
    if (Object.keys(params)?.length == 0) {
      setSearchParams({ userId: loginUserId });
    }
  }, [params]);

  //life cycle
  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params?.userId : loginUserId.toString(),
    });

    if (params?.title) {
      setValue({ title: params.title });
    }
  }, []);

  //api call
  const getTradingTrade = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }

    let payloadObj = {
      userId: Number(params?.userId),
      title: params.title || null,
      marketName: params?.marketName,
      symbolName: params?.symbolName,
      tradeStatus: params?.tradeStatus || null,
      startDate: params?.startDate || null,
      endDate: params?.endDate || null,
      // valan: params?.valan || valueRef.current.valan || null,
    };
    let response = await APICall(
      "post",
      `${EndPoints.getTrade}?page=${params.page || 1}&limit=${
        params?.limit || 10
      }`,
      payloadObj
    );
    // console.log("trades", response?.data?.data);
    setTotalCounts(response?.data?.data?.totalCount);
    return response?.data?.data;
  };

  const {
    isLoading,
    isFetching,
    data: tradeData,
    error: tradeDataError,
    refetch,
  } = useQuery({
    queryKey: ["tradingTrade", params],
    queryFn: getTradingTrade,
    enabled: Object.keys(params)?.length > 0,
  });

  if (tradeDataError) {
    notification.error({ message: tradeDataError?.message });
  }

  const getAllData = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }

    let payloadObj = {
      userId: Number(params?.userId),
      title: params.title || null,
      marketName: params?.marketName,
      symbolName: params?.symbolName,
      tradeStatus: params?.tradeStatus || null,
      startDate: params?.startDate || null,
      endDate: params?.endDate || null,
    };
    let response = await APICall("post", `${EndPoints.getTrade}`, payloadObj);
    return response;
  };

  const {
    isLoading: isLoadingCSV,
    data: allTradeData,
    mutate,
  } = useMutation({
    mutationFn: getAllData,
    onSuccess: (res) => {
      if (res.status === 200) {
        // console.log("allTradeData", res);
        let allTradeData = res?.data?.data?.trades;
        let csvData = allTradeData?.map((el) => {
          let date = el?.tradeTime;
          if (date) {
            date = dayjs(el?.tradeTime)?.format("YYYY-MM-DD");
          }
          return {
            ...el,
            tradeTime: date,
            userId: el?.customer?.[0]?.userId,
            accountName: el?.customer?.[0]?.accountName,
            tradePattern: el?.tradePattern === "buy" ? "B" : "S",
          };
        });

        const csv = convertToCSV(csvData, CSVColumns);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "trade_data.csv");
      } else {
        notification.error({ message: res.data.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error?.message });
    },
  });

  const deleteTrade = async (record, index, key) => {
    const id = record?._id;
    // const customerId = record?.customerId?._id;
    const customerId = record?.customer?.[0]?._id;
    let obj = {
      deviceType: "web",
      tradeIpAddress: record?.tradeIpAddress,
    };

    try {
      let response = await APICall(
        "delete",
        `${EndPoints.deleteTrade}${id}&customerId=${customerId}`,
        obj
      );

      if (response.status === 200) {
        notification.success({ message: response.data.message });

        refetch();
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.log("error");
    }
  };

  const findTrade = async () => {
    let obj = {
      ...value,
      userId: valueRef.current?.userId || loginUserId,
      ...valueRef.current?.value,
      startDate: valueRef?.current?.startDate
        ? valueRef?.current?.startDate?.toISOString()
        : null,
      endDate: valueRef?.current?.endDate
        ? valueRef.current?.endDate?.toISOString()
        : null,
      page: 1,
    };
    if (params?.limit) {
      obj.limit = params?.limit;
    }

    let setParam = Object.keys(obj)?.reduce((acc, curr) => {
      if (obj[curr]) {
        acc[curr] = obj[curr];
      } else {
        return acc;
      }
      return acc;
    }, {});

    setSearchParams({ ...setParam });
  };

  const handleCancel = () => {
    setShowModal({ name: null, flag: false, record: null });
  };

  const handleEdit = (record, key) => {
    setShowModal({ name: "edit", flag: true, record: { ...record, key } });
  };

  const handleNext = (newPage) => {
    setSearchParams({ ...params, page: newPage?.toString() });
  };

  const handleClear = () => {
    valueRef.current = {};
    dispatch(setUserClearFilter(true));
    setValue({ title: null });
    setSearchParams({ userId: loginUserId });
  };

  const onChangeDropDown = (name, selectedValue, selectedOptions) => {
    setValue({ ...value, [name]: selectedValue?.[0] });
  };

  const handleExport = () => {
    mutate();
    // const csvTrades = tradeData?.map((el) => {
    //   return {
    //     ...el,
    //     userId: el?.customer?.[0]?.userId,
    //     accountName: el?.customer?.[0]?.accountName,
    //   };
    // });
    // let csvData = tradeData?.trades?.map((el) => {
    //   let date = el?.tradeTime;
    //   if (date) {
    //     date = dayjs(el?.tradeTime)?.format("YYYY-MM-DD");
    //   }
    //   return {
    //     ...el,
    //     tradeTime: date,
    //     userId: el?.customer?.[0]?.userId,
    //     accountName: el?.customer?.[0]?.accountName,
    //     tradePattern: el?.tradePattern === "buy" ? "B" : "S",
    //   };
    // });

    // const csv = convertToCSV(csvData, CSVColumns);
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // saveAs(blob, "trade_data.csv");
  };

  //column
  const columns = [
    {
      title: "DEVICE",
      dataIndex: "deviceType",
      key: "D",
      width: "75px",
      align: "left",
      render: (text, record) => {
        return (
          <Tooltip title={`${record?.marketStatus} trade`}>
            <div style={{ display: "flex", gap: "5px" }}>
              <div>{record?.deviceType}</div>
              <ActiveDot status={record?.marketStatus}></ActiveDot>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "TRADE BY",
      dataIndex: "tradeBy",
      width: "6rem",
      key: "tradeBy",
      align: "left",
      render: (text, record) => {
        const isSame =
          record?.tradeBy?.[0]?.userId === record?.customer?.[0]?.userId;
        return (
          !isLoading && (
            <div style={{ color: !isSame ? "red" : "black" }}>
              {/* -{" "} */}
              {`${record?.tradeBy[0]?.userId} (${record?.tradeBy[0]?.accountName})`}
            </div>
          )
        );
      },
    },
    {
      title: "CLIENT INFO",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{`${record?.customer[0]?.userId} (${record?.customer[0]?.accountName})`}</div>
          )
        );
      },
    },
    {
      title: "Trade Id",
      dataIndex: "orderNo",
      key: "orderNo",
      width: "5rem",
      align: "left",
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{record?.tradeNumber ? record?.tradeNumber : "-"}</div>
          )
        );
      },
    },
    {
      title: "MARKET",
      dataIndex: "marketName",
      key: "market",
      width: "6rem",
      align: "left",
    },
    {
      title: "SCRIPT",
      dataIndex: "symbolName",
      key: "script",
      align: "left",
      width: "12rem",
      render: (text, record) => {
        const date = record?.expiryDate ? record?.expiryDate : "";
        // const date = valanBillDate(record?.expiryDate, false);
        const opt = record?.marketName?.endsWith("OPT");
        const name = opt
          ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
              record?.strikePrice ? record?.strikePrice : ""
            }`
          : record?.symbolName;
        return (
          !isLoading && (
            <div>
              {name} {date}
            </div>
          )
        );
      },
    },
    {
      title: "ORDER TYPE",
      dataIndex: "title",
      key: "type",
      align: "left",
      width: "6rem",
    },
    {
      title: "B/S",
      dataIndex: "tradePattern",
      key: "buy/sell",
      width: "4rem",
      align: "left",
    },
    {
      title: "QTY (LOT)",
      dataIndex: "lot",
      key: "lot",
      align: "left",
      width: "9rem",
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        const quantity = formatNumber(record?.quantity);
        const data =
          record?.marketName === "NSE EQU" ? quantity : `${quantity} (${lot})`;
        return !isLoading && <div>{data}</div>;
      },
    },
    {
      title: "ORDER RATE",
      dataIndex: "tradePrice",
      key: "orderPrice",
      align: "left",
      width: "7rem",
      render: (text, record) => {
        const rate = findMarket(record?.marketName)
          ? limitNumberLength(record?.tradePrice)
          : record?.tradePrice?.toFixed(2);
        return !isLoading && <div>{rate}</div>;
      },
    },
    {
      title: "NET RATE",
      dataIndex: "tradePrice",
      key: "netPrice",
      width: "7rem",
      align: "left",
      render: (text, record) => {
        const netRate = findMarket(record?.marketName)
          ? limitNumberLength(record?.netRate)
          : record?.netRate?.toFixed(2);
        return !isLoading && <div>{netRate}</div>;
      },
    },
    {
      title: "STATUS",
      dataIndex: "tradeStatus",
      key: "tradeStatus",
      width: "7rem",
      align: "left",
    },
    {
      title: "ORDER-TIME",
      dataIndex: "oTime",
      key: "oTime",
      width: "7rem",
      align: "left",
      render: (text, record) => {
        const finalDate = valanBillDate(record?.tradeTime, true);
        return !isLoading && <div>{finalDate ? finalDate : "-"}</div>;
      },
    },
    {
      title: "TRADE-TIME",
      dataIndex: "time",
      key: "time",
      width: "7rem",
      align: "left",
      render: (text, record) => {
        const finalDate = valanBillDate(record?.executeTime, true);
        return !isLoading && <div>{finalDate ? finalDate : "-"}</div>;
      },
    },
    {
      title: "IP",
      dataIndex: "tradeIpAddress",
      key: "ip",
      width: "10rem",
      align: "left",
      render: (text, record) => {
        return !isLoading && <div>{record?.tradeIpAddress}</div>;
      },
    },

    {
      title: "MODIFY",
      dataIndex: "oTime",
      key: "oTime",
      width: "5rem",
      align: "center",
      render: (text, record) => {
        return (
          !isLoading && (
            <div style={{ color: record?.modified ? "red" : "black" }}>
              {record?.modified ? "modified" : "-"}
            </div>
          )
        );
      },
    },
    isAllow && (additional?.editTrade || additional?.tradeDelete)
      ? {
          title: "ACTION",
          dataIndex: "modify",
          key: "modify",
          width: "5rem",
          align: "center",
          render: (text, record, index) => {
            return (
              !isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  {(additional?.editTrade &&
                    record?.deviceType !== "system" &&
                    record?.tradeStatus !== "pending") ||
                  isOwner ? (
                    <EditIcon
                      onClick={() => handleEdit(record, "tradingTrade")}
                    />
                  ) : (
                    "-"
                  )}
                  {additional?.tradeDelete &&
                    (record?.deviceType !== "system" || isOwner ? (
                      // <Popconfirm
                      //   title="delete the trade"
                      //   description={`Are you sure to delete ${record?.tradePattern} ${record?.symbolName} ${record?.quantity} (${record?.lot}) @ ${record?.tradePrice}   ?`}
                      //   onConfirm={() =>
                      //     setShowModal({
                      //       name: "delete",
                      //       flag: true,
                      //       record: record,
                      //     })
                      //   }
                      //   placement="topRight"
                      //   okText="Yes"
                      //   cancelText="No"
                      // >
                      <DeleteIcon
                        onClick={() =>
                          setShowModal({
                            name: "delete",
                            flag: true,
                            record: record,
                          })
                        }
                      />
                    ) : (
                      // </Popconfirm>
                      "-"
                    ))}
                </div>
              )
            );
          },
        }
      : {
          width: "0rem",
        },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[10, 15]}>
          <GetDate valueRef={valueRef} />
          <MarketScriptList valueRef={valueRef} />
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDropDown
              title={"Order Type"}
              placeholder="Order Type"
              name="title"
              width={"98%"}
              showSearch={true}
              options={options.trading.orderType}
              $onChange={onChangeDropDown}
              $value={value?.title}
            />
          </Col>
          {loginAccountType !== "customer" && (
            <UserFilterComponant valueRef={valueRef} />
          )}
        </Row>

        <Row style={{ marginTop: "2rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton
              type="primary"
              // loading={isLoading}
              onClick={findTrade}
            >
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleClear}>
              Clear Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton
              type="primary"
              onClick={handleExport}
              loading={isLoadingCSV}
            >
              Export
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>

      <ThemeCardTable
        buttonType={true}
        buttonTitle={buttonTitles}
        data={!isLoading && !isFetching && tradeData?.trades}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={isLoading || isFetching}
        filterType="trade"
      />

      <Pagination
        current={Number(params.page) || 1}
        pageSize={params?.limit ? params?.limit : 10}
        showSizeChanger={false}
        total={totalCounts}
        onChange={handleNext}
        queryKey="tradingTrade"
        style={{ textAlign: "center", marginBottom: "2rem" }}
      />

      {showModal?.flag && showModal?.name === "edit" && (
        <EditTrade handleCancel={handleCancel} record={showModal.record} />
      )}
      {showModal?.flag && showModal?.name === "delete" && (
        <VerificationModal
          handleCancel={handleCancel}
          record={showModal.record}
          query={"tradingTrade"}
          type="delete"
          title={"Delete Trade"}
        />
      )}
    </>
  );
}

export default TradingTrade;

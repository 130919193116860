import { useDispatch, useSelector } from "react-redux";
import ThemeInputNumber from "../../../../components/ThemeInputNumber/ThemeInputNumber";
import { Col, Form, Input, InputNumber, Row, Space, Tooltip } from "antd";
import ThemeCard from "../../../../components/ThemeCard";
import AccountDetailsRadioView from "../AccountDetailsRadioView";
import { QuestionCircleOutlined } from "@ant-design/icons";
import useGetCurrentUserRole from "../../../../hooks/useGetCurrentUserRole";
import React from "react";
import { StyleSpan } from "../style";

const ClientBrokerageView = ({ tab, view, is_edits, form }) => {
  const dispatch = useDispatch();
  //redux
  // const form_data = useSelector(
  //   (state) => state.userAccountSlice?.[tab]?.[view]
  // );
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const brokerageFix = Form.useWatch(`brokerageType$${tab}`, form) === "fixed";

  const canBlockPercentage =
    Form.useWatch(`partnershipWith`, form) === "brokerage";
  // const minimumBrokerage = Form.useWatch(`minimumBrokerageRequire`, form);
  const showBrokerage = Form.useWatch(`minimumBrokerageRequire`, form);
  let deliverysvalue = Form.useWatch(`deliveryTotal$${tab}`, form);

  const marketGiven = useSelector(
    (state) => state?.globleSlice?.constantValue?.marketAccess
  );

  const parentDetails = useSelector(
    (state) => state.authSlice.MarketAccessDetails
  );
  const { userRole } = useGetCurrentUserRole();
  const isRent = parentDetails?.accountDetails?.partnershipWith === "rent";
  const isMaster = accountType === "masterAdmin";
  const notEditable =
    (isRent || isMaster) && !marketGiven?.includes(tab) ? false : true;

  const editable = marketGiven?.includes(tab);
  let parentMinimumBrokerage =
    parentDetails?.accountDetails?.minimumBrokerageRequire;
  let pr =
    parentDetails?.brokarageDetails?.[tab]?.remainingPercentage ||
    100 - parentDetails?.brokarageDetails?.[tab]?.percentage;
  // let pr = remainingPercentage==0? 0:remainingPercentage;

  const deliveryTotal = parentDetails?.brokarageDetails?.[tab]?.deliveryTotal;
  const intraDayTotal = parentDetails?.brokarageDetails?.[tab]?.intradayTotal;
  const deliveryBuy = parentDetails?.brokarageDetails?.[tab]?.deliveryBuy;
  const deliverySell = parentDetails?.brokarageDetails?.[tab]?.deliverySell;
  const intradayBuy = parentDetails?.brokarageDetails?.[tab]?.intradayBuy;
  const intradaySell = parentDetails?.brokarageDetails?.[tab]?.intradaySell;

  return (
    <Form form={form}>
      <ThemeCard
        title={
          accountType === "customer" ? "Client Brokerage" : "My Partnership"
        }
        headerColor
        style={{ marginBottom: "2rem" }}
      >
        {/* {showBrokerage ||
        (parentMinimumBrokerage && accountType === "customer") ? ( */}
        <Row justify={"center"} style={{ marginBottom: "1rem" }}>
          <Col xs={24} sm={24} md={18} lg={12} xl={8} align={"center"}>
            <AccountDetailsRadioView
              formProps={{
                name: `brokerageType$${tab}`,
                initialValue:
                  isMaster || isRent
                    ? ["NSE OPT", "MCX", "MCX OPT"].includes(tab)
                      ? "fixed"
                      : "percentage"
                    : parentDetails?.brokarageDetails[tab]?.brokerageType,
              }}
              disabled={
                (is_edits && notEditable) ||
                tab === "NSE EQU" ||
                (!isMaster && !isRent && !is_edits && parentMinimumBrokerage)
                // ||
                // (parentMinimumBrokerage && !is_edits)
              }
              title={
                <StyleSpan>
                  <div>Brokerage Type</div>
                  <Tooltip
                    title={`Fix : Brokerage per lot , Percentage : On total value of order (0.01 : on 1,00,000 -> 10 rs.)`}
                    placement="topRight"
                  >
                    <QuestionCircleOutlined style={{ cursor: "pointer" }} />
                  </Tooltip>
                </StyleSpan>
              }
              $width={"18rem"}
              name="brokerageType"
              required
              options={[
                {
                  value: "fixed",
                  label: "Fix",
                },
                {
                  value: "percentage",
                  label: "%",
                },
              ]}
            />
          </Col>
        </Row>
        {/* ) : null} */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "1.5rem",
          }}
        >
          {accountType === "customer" ? (
            <div style={{ width: "100%" }}>
              <Row
                justify={"center"}
                style={{
                  marginBottom: "1rem",
                }}
              >
                <Col xs={24} sm={7} md={5} lg={4}>
                  <label className="common-label">Delivery Total</label>
                  <Form.Item
                    name={`deliveryTotal$${tab}`}
                    dependencies={[`deliverySell$${tab}`, `deliveryBuy$${tab}`]}
                    // initialValue={0}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (!brokerageFix &&
                              getFieldValue(`deliveryTotal$${tab}`) >=
                                deliveryTotal &&
                              getFieldValue(`deliveryTotal$${tab}`) >= 0 &&
                              getFieldValue(`deliveryTotal$${tab}`) < 1) ||
                            (brokerageFix &&
                              getFieldValue(`deliveryTotal$${tab}`) >=
                                deliveryTotal &&
                              getFieldValue(`deliveryTotal$${tab}`) >= 0) ||
                            (brokerageFix &&
                              (isMaster || isRent) &&
                              getFieldValue(`deliveryTotal$${tab}`) >= 0) ||
                            (!brokerageFix &&
                              (isMaster || isRent) &&
                              getFieldValue(`deliveryTotal$${tab}`) >= 0 &&
                              getFieldValue(`deliveryTotal$${tab}`) < 1)
                          ) {
                            if (
                              getFieldValue(`deliveryTotal$${tab}`) !== null
                            ) {
                              return Promise.resolve();
                            }
                          }
                          if (isMaster || isRent) {
                            return Promise.reject(
                              new Error(
                                `Please add positive number ${
                                  brokerageFix ? "" : `between 0 to 0.99`
                                } `
                              )
                            );
                          }
                          return Promise.reject(
                            new Error(
                              `You can not give Delivery Total less then ${
                                brokerageFix
                                  ? deliveryTotal
                                  : `${deliveryTotal} and more then 0.99`
                              } `
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      name={`deliveryTotal$${tab}`}
                      step={0.01}
                      className="w-100"
                      addonAfter={brokerageFix ? "Fix" : "%"}
                      onChange={(e) => {
                        form.setFields([
                          {
                            name: `deliveryBuy$${tab}`,
                            value: e / 2,
                            touched: true,
                          },
                          {
                            name: `deliverySell$${tab}`,
                            value: e / 2,
                            touched: true,
                          },
                        ]);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={2}
                  lg={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  =
                </Col>
                <Col xs={24} sm={7} md={5} lg={4}>
                  <label className="common-label">Delivery Buy</label>
                  <Form.Item
                    name={`deliveryBuy$${tab}`}
                    dependencies={[
                      `deliverySell$${tab}`,
                      `deliveryTotal$${tab}`,
                    ]}
                    // initialValue={0}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value, c) {
                          if (
                            (getFieldValue(`deliveryBuy$${tab}`) >=
                              deliveryBuy &&
                              getFieldValue(`deliveryBuy$${tab}`) !== null &&
                              getFieldValue(`deliveryBuy$${tab}`) !== "" &&
                              getFieldValue(`deliveryBuy$${tab}`) <=
                                getFieldValue(`deliveryTotal$${tab}`)) ||
                            ((isMaster || isRent) &&
                              getFieldValue(`deliveryBuy$${tab}`) !== null &&
                              getFieldValue(`deliveryBuy$${tab}`) !== "" &&
                              getFieldValue(`deliveryBuy$${tab}`) <=
                                getFieldValue(`deliveryTotal$${tab}`))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              getFieldValue(`deliveryTotal$${tab}`)
                                ? `You can not give deliveryBuy less then ${deliveryBuy} and more then ${getFieldValue(
                                    `deliveryTotal$${tab}`
                                  )}
                                `
                                : "Pleaser Enter Delivery Total"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="w-100"
                      name={`deliveryBuy$${tab}`}
                      step={0.01}
                      addonAfter={brokerageFix ? "Fix" : "%"}
                      onChange={(e) => {
                        if (form.getFieldValue(`deliveryTotal$${tab}`)) {
                          let diff =
                            form.getFieldValue(`deliveryTotal$${tab}`) - e;
                          form.setFields([
                            {
                              name: `deliverySell$${tab}`,
                              value: Number(diff.toFixed(4)),
                              touched: true,
                            },
                          ]);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={2}
                  lg={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  +
                </Col>
                <Col xs={24} sm={7} md={5} lg={4}>
                  <label className="common-label">Delivery Sell</label>
                  <Form.Item
                    name={`deliverySell$${tab}`}
                    dependencies={[
                      `deliveryBuy$${tab}`,
                      `deliveryTotal$${tab}`,
                    ]}
                    // initialValue={0}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (getFieldValue(`deliverySell$${tab}`) >=
                              deliverySell &&
                              getFieldValue(`deliverySell$${tab}`) !== null &&
                              getFieldValue(`deliverySell$${tab}`) !== "" &&
                              getFieldValue(`deliverySell$${tab}`) <=
                                getFieldValue(`deliveryTotal$${tab}`)) ||
                            ((isMaster || isRent) &&
                              getFieldValue(`deliverySell$${tab}`) !== null &&
                              getFieldValue(`deliverySell$${tab}`) !== "" &&
                              getFieldValue(`deliverySell$${tab}`) <=
                                getFieldValue(`deliveryTotal$${tab}`))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              getFieldValue(`deliveryTotal$${tab}`)
                                ? `You can not give deliverySell less then ${deliverySell} and more then ${getFieldValue(
                                    `deliveryTotal$${tab}`
                                  )}
                                `
                                : "Pleaser Enter Delivery Total"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      name={`deliverySell$${tab}`}
                      step={0.01}
                      className="w-100"
                      addonAfter={brokerageFix ? "Fix" : "%"}
                      onChange={(e) => {
                        if (form.getFieldValue(`deliveryTotal$${tab}`)) {
                          let diff =
                            form.getFieldValue(`deliveryTotal$${tab}`) - e;
                          form.setFields([
                            {
                              name: `deliveryBuy$${tab}`,
                              value: Number(diff.toFixed(4)),
                              touched: true,
                            },
                          ]);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                justify={"center"}
                style={{
                  marginBottom: window.innerWidth < 575 ? "1rem" : "0rem",
                }}
              >
                <Col xs={24} sm={7} md={5} lg={4}>
                  <label className="common-label">Intraday Total</label>
                  <Form.Item
                    name={`intradayTotal$${tab}`}
                    dependencies={[`intradayBuy$${tab}`, `intradaySell$${tab}`]}
                    // initialValue={0}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (!brokerageFix &&
                              getFieldValue(`intradayTotal$${tab}`) >=
                                intraDayTotal &&
                              getFieldValue(`intradayTotal$${tab}`) >= 0 &&
                              getFieldValue(`intradayTotal$${tab}`) < 1) ||
                            (brokerageFix &&
                              getFieldValue(`intradayTotal$${tab}`) >=
                                intraDayTotal &&
                              getFieldValue(`intradayTotal$${tab}`) >= 0) ||
                            (!brokerageFix &&
                              (isMaster || isRent) &&
                              getFieldValue(`intradayTotal$${tab}`) >= 0 &&
                              getFieldValue(`intradayTotal$${tab}`) < 1) ||
                            (brokerageFix &&
                              (isMaster || isRent) &&
                              getFieldValue(`intradayTotal$${tab}`) >= 0)
                          ) {
                            if (
                              getFieldValue(`intradayTotal$${tab}`) !== null
                            ) {
                              return Promise.resolve();
                            }
                          }
                          if (isMaster || isRent) {
                            return Promise.reject(
                              new Error(
                                `Please add positive number ${
                                  brokerageFix ? "" : `between 0 to 0.99`
                                }`
                              )
                            );
                          }
                          return Promise.reject(
                            new Error(
                              `You can not give Intraday Total less then ${
                                brokerageFix
                                  ? intraDayTotal
                                  : `${intraDayTotal} and more then 0.99`
                              }`
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      name={`intradayTotal$${tab}`}
                      step={0.01}
                      className="w-100"
                      addonAfter={brokerageFix ? "Fix" : "%"}
                      onChange={(e) => {
                        form.setFields([
                          {
                            name: `intradayBuy$${tab}`,
                            value: e / 2,
                            touched: true,
                          },
                          {
                            name: `intradaySell$${tab}`,
                            value: e / 2,
                            touched: true,
                          },
                        ]);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={2}
                  lg={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  =
                </Col>
                <Col xs={24} sm={7} md={5} lg={4}>
                  <label className="common-label">Intraday Buy</label>
                  <Form.Item
                    name={`intradayBuy$${tab}`}
                    dependencies={[
                      `intradayTotal$${tab}`,
                      `intradaySell$${tab}`,
                    ]}
                    // initialValue={0}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (getFieldValue(`intradayBuy$${tab}`) >=
                              intradayBuy &&
                              getFieldValue(`intradayBuy$${tab}`) !== null &&
                              getFieldValue(`intradayBuy$${tab}`) !== "" &&
                              getFieldValue(`intradayBuy$${tab}`) <=
                                getFieldValue(`intradayTotal$${tab}`)) ||
                            ((isMaster || isRent) &&
                              getFieldValue(`intradayBuy$${tab}`) !== null &&
                              getFieldValue(`intradayBuy$${tab}`) !== "" &&
                              getFieldValue(`intradayBuy$${tab}`) <=
                                getFieldValue(`intradayTotal$${tab}`))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              getFieldValue(`intradayTotal$${tab}`)
                                ? `You can not give Intraday Buy less then ${intradayBuy} and more then ${getFieldValue(
                                    `intradayTotal$${tab}`
                                  )}
                                `
                                : "Please Enter Intraday Total"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      name={`intradayBuy$${tab}`}
                      step={0.01}
                      className="w-100"
                      addonAfter={brokerageFix ? "Fix" : "%"}
                      onChange={(e) => {
                        if (form.getFieldValue(`intradayTotal$${tab}`)) {
                          let diff =
                            form.getFieldValue(`intradayTotal$${tab}`) - e;
                          form.setFields([
                            {
                              name: `intradaySell$${tab}`,
                              value: Number(diff.toFixed(4)),
                              touched: true,
                            },
                          ]);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={1}
                  sm={1}
                  md={2}
                  lg={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  +
                </Col>
                <Col xs={24} sm={7} md={5} lg={4}>
                  <label className="common-label">Intraday Sell</label>
                  <Form.Item
                    name={`intradaySell$${tab}`}
                    dependencies={[
                      `intradayTotal$${tab}`,
                      `intradayBuy$${tab}`,
                    ]}
                    // initialValue={0}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            (getFieldValue(`intradaySell$${tab}`) >=
                              intradaySell &&
                              getFieldValue(`intradaySell$${tab}`) !== null &&
                              getFieldValue(`intradaySell$${tab}`) !== "" &&
                              getFieldValue(`intradaySell$${tab}`) <=
                                getFieldValue(`intradayTotal$${tab}`)) ||
                            ((isMaster || isRent) &&
                              getFieldValue(`intradaySell$${tab}`) !== null &&
                              getFieldValue(`intradaySell$${tab}`) !== "" &&
                              getFieldValue(`intradaySell$${tab}`) <=
                                getFieldValue(`intradayTotal$${tab}`))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              getFieldValue(`intradayTotal$${tab}`)
                                ? `You can not give Intraday Sell less then ${intradaySell} and more then ${getFieldValue(
                                    `intradayTotal$${tab}`
                                  )}
                                `
                                : "Pleaser Enter Intraday Total"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      className="w-100"
                      name={`intradaySell$${tab}`}
                      step={0.01}
                      addonAfter={brokerageFix ? "Fix" : "%"}
                      onChange={(e) => {
                        if (form.getFieldValue(`intradayTotal$${tab}`)) {
                          let diff =
                            form.getFieldValue(`intradayTotal$${tab}`) - e;
                          form.setFields([
                            {
                              name: `intradayBuy$${tab}`,
                              value: Number(diff.toFixed(4)),
                              touched: true,
                            },
                          ]);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ) : (
            <Row justify={"space-around"} gutter={[7, 7]}>
              <Col xs={24} sm={7} md={8} lg={8}>
                <ThemeInputNumber
                  title="Percentage"
                  name={`percentage$${tab}`}
                  extraProps={{
                    addonAfter: "%",
                  }}
                  inputProps={{
                    name: `percentage$${tab}`,
                    disabled: canBlockPercentage,
                  }}
                  formProps={{
                    name: `percentage$${tab}`,
                    // initialValue: brokerageFix ? 0 : 0.0,
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          value = getFieldValue(`percentage$${tab}`);
                          if (
                            (value <= pr &&
                              value !== null &&
                              value !== "" &&
                              value >= 0) ||
                            ((isMaster || isRent) &&
                              value >= 0 &&
                              value !== null &&
                              value !== "" &&
                              value <= 100) ||
                            canBlockPercentage
                          ) {
                            return Promise.resolve();
                          }
                          if (isMaster || isRent) {
                            return Promise.reject(
                              new Error(
                                `Please fill this field with positive number between 0 to 100`
                              )
                            );
                          }
                          return Promise.reject(
                            new Error(
                              `You can not give Percentage less then 0 and more then ${pr}`
                            )
                          );
                        },
                      }),
                    ],
                  }}

                  // titleFontWeight={400}
                />
                {/* </Form.Item> */}
              </Col>
              <Col xs={24} sm={7} md={8} lg={8}>
                <ThemeInputNumber
                  title="Delivery (Buy + Sell)"
                  extraProps={{
                    addonAfter: brokerageFix ? "Fix" : "%",
                  }}
                  inputProps={{
                    name: `deliveryTotal$${tab}`,
                    disabled: showBrokerage === false,
                  }}
                  titleFontWeight={400}
                  formProps={{
                    name: `deliveryTotal$${tab}`,
                    // initialValue: brokerageFix ? 0 : 0.0,
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          value = getFieldValue(`deliveryTotal$${tab}`);
                          if (
                            ((isMaster || isRent) &&
                              value >= 0 &&
                              value !== "" &&
                              value !== null &&
                              brokerageFix) ||
                            ((isMaster || isRent) &&
                              value >= 0 &&
                              value !== "" &&
                              value !== null &&
                              value < 1 &&
                              !brokerageFix) ||
                            (value >= deliveryTotal &&
                              value >= 0 &&
                              value !== null &&
                              value !== "" &&
                              brokerageFix) ||
                            (value >= deliveryTotal &&
                              value >= 0 &&
                              value !== null &&
                              value !== "" &&
                              value < 1 &&
                              !brokerageFix) ||
                            showBrokerage === false
                          ) {
                            return Promise.resolve();
                          }
                          if (isMaster || isRent) {
                            return Promise.reject(
                              new Error(
                                `Please fill this field with positive number ${
                                  brokerageFix ? "" : `between 0 to 0.99`
                                } `
                              )
                            );
                          }
                          return Promise.reject(
                            new Error(
                              `You can not give delivery less then ${
                                brokerageFix
                                  ? deliveryTotal
                                  : `${deliveryTotal} and more then 0.99`
                              } `
                            )
                          );
                        },
                      }),
                    ],
                  }}
                />
              </Col>
              <Col xs={24} sm={7} md={8} lg={8}>
                <ThemeInputNumber
                  title="Intraday (Buy + Sell)"
                  step={0.001}
                  extraProps={{
                    input_type: "number",
                    addonAfter: brokerageFix ? "Fix" : "%",
                  }}
                  inputProps={{
                    name: `intradayTotal$${tab}`,
                    disabled: showBrokerage === false,
                  }}
                  titleFontWeight={400}
                  formProps={{
                    name: `intradayTotal$${tab}`,
                    // initialValue: brokerageFix ? 0 : 0.0,
                    rules: [
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          value = getFieldValue(`intradayTotal$${tab}`);
                          if (
                            ((isMaster || isRent) &&
                              value >= 0 &&
                              value !== null &&
                              value !== "" &&
                              brokerageFix) ||
                            ((isMaster || isRent) &&
                              value >= 0 &&
                              value !== null &&
                              value !== "" &&
                              value < 1 &&
                              !brokerageFix) ||
                            (value >= intraDayTotal &&
                              value >= 0 &&
                              value !== null &&
                              value !== "" &&
                              brokerageFix) ||
                            (value >= intraDayTotal &&
                              value >= 0 &&
                              value !== null &&
                              value !== "" &&
                              value < 1 &&
                              !brokerageFix) ||
                            showBrokerage === false
                          ) {
                            return Promise.resolve();
                          }
                          if (isMaster || isRent) {
                            return Promise.reject(
                              new Error(
                                `Please fill this field with positive number ${
                                  brokerageFix ? "" : `between 0 to 0.99`
                                } `
                              )
                            );
                          }
                          return Promise.reject(
                            new Error(
                              `You can not give delivery less then ${
                                brokerageFix
                                  ? intraDayTotal
                                  : `${intraDayTotal} and more then 0.99`
                              } `
                            )
                          );
                        },
                      }),
                    ],
                  }}
                />
              </Col>
            </Row>
          )}
        </div>
      </ThemeCard>
    </Form>
  );
};

export default React.memo(ClientBrokerageView);

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  "NSE FUT": { edited: [], blocked: [] },
  "NSE OPT": { edited: [], blocked: [] },
  "NSE EQU": { edited: [], blocked: [] },
  MCX: { edited: [], blocked: [] },
  "MCX OPT": { edited: [], blocked: [] },
  FOREX: { edited: [], blocked: [] },
  COMEX: { edited: [], blocked: [] },
  GLOBEX: { edited: [], blocked: [] },
};

const scriptSettingsRequestSlice = createSlice({
  name: "scriptSettingsRequestSlice",
  initialState,
  reducers: {
    setBlockedScripts: (state, action) => {
      return {
        ...state,
        [action.payload.tab]: {
          edited: state[action.payload.tab].edited || [],
          blocked: action.payload.blocked,
        },
      };
    },

    setEditData: (state, action) => {
      return {
        ...state,
        [action.payload.tab]: {
          blocked: state[action.payload.tab].blocked || [],
          edited: action.payload.edited,
        },
      };
    },
    removeBlockedAndEdited: (state, action) => {
      return { ...initialState };
    },
  },
});

export default scriptSettingsRequestSlice.reducer;

export const scriptSettingRequestSelector = (state) =>
  state.scriptSettingsRequestSlice;

export const { setBlockedScripts, setEditData, removeBlockedAndEdited } =
  scriptSettingsRequestSlice.actions;

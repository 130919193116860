import React from "react";
import { Row, theme } from "antd";
import useColorChange from "../../hooks/useColorChange";

const ModalCellView = React.memo(
  ({
    name = "",
    title,
    currentValue,
    checkUpdates = false,
    hideValue = false,
  }) => {
    const token = theme.useToken().token;
    const { color, backgroundColor } = useColorChange({
      currentValue: checkUpdates ? currentValue : null,
      defaultBgColor: token.colorBgBase,
      defaultColor: token.colorTextLabel,
    });

    return (
      <Row style={{ backgroundColor }}>
        <div className="cell-title" style={{ color }}>
          {title}
        </div>
        {hideValue ? null : (
          <div className="cell-number" style={{ color }}>
            {currentValue || "~"}
          </div>
        )}
      </Row>
    );
  }
);
export default ModalCellView;

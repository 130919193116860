import React, { useEffect, useRef, useState } from "react";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import ThemeCard from "../../components/ThemeCard";
import { Button, Col, Modal, Row, Table } from "antd";
import styled from "styled-components";
import ThemeCardTable from "../../components/ThemeCardTable";
import { StyledButton } from "../../styles/commonStyle";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";

const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }
`;

const EditButton = styled(Button)`
  border-radius: ${(props) => props.theme.token?.borderRadiusLG};
  font-size: 1rem;
  background-color: ${(props) => props.theme.token?.colorPrimary};
  box-shadow: ${(props) => props.theme.token?.boxShadowNone};
  color: white;

  /* margin-right: 2rem; */
  /* height: 3rem; */
  /* width: 100%; */
`;

const data = [
  {
    client: "alok",
    time: "12:02:41",
    ip: "192.168.1.23",
  },
];

const UserEditLog = () => {
  const valueRef = useRef({});
  const [show, setShow] = useState({ flag: false, record: null });

  const getUserEditLog = async () => {
    let response = await APICall("get", EndPoints.getUserEditLog);
    console.log("response", response);
    return response?.data?.data;
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["userEditLog"],
    queryFn: getUserEditLog,
  });

  console.log("data", data);

  const handleEdit = (value) => {
    setShow({ flag: true, record: value });
  };
  const handleCencel = () => {
    setShow({ flag: false, record: null });
  };

  // column
  const columns = [
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "center",
      width: "5rem",
      //   render: (text, record) => {
      //     return <span>{record?.customerId?.accountName}</span>;
      //   },
    },
    {
      title: "CHANGE LOGS",
      dataIndex: "logs",
      key: "logs",
      align: "center",
      width: "20rem",
      render: (text, record) => {
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr)",
              gap: "0.3rem",
            }}
          >
            <EditButton type="primary" onClick={handleEdit}>
              Basic Info
            </EditButton>
            <EditButton type="primary">Account Details</EditButton>
            <EditButton type="primary">Brokerage Details</EditButton>
            <EditButton type="primary">Margin Details</EditButton>
            <EditButton type="primary">Scriptwise Details</EditButton>
          </div>
        );
      },
    },
    {
      title: "TIME",
      dataIndex: "time",
      key: "time",
      width: "8rem",
      align: "center",
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      width: "5rem",
      align: "center",
    },
  ];
  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]}>
          <UserFilterComponant valueRef={valueRef} />
        </Row>
        <Row style={{ marginTop: "3rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={8} lg={3} xl={2}>
            <StyledButton type="primary">Search</StyledButton>
          </Col>
          <Col xs={24} sm={12} md={8} lg={3} xl={2}>
            <StyledButton type="primary">Reset</StyledButton>
          </Col>
        </Row>
      </ThemeCard>

      <ThemeCardTable column={columns} buttonType={false} data={data} />
      <Modal
        title={"Basic Info"}
        open={show?.flag}
        onCancel={handleCencel}
        footer={false}
        width={1200}
      >
        <StyTable
          className="userTable"
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="_id"
        />
      </Modal>
    </>
  );
};

export default UserEditLog;

import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import React, { useEffect, useState } from "react";
import APICall from "../APICall";
import EndPoints from "../APICall/EndPoints";

const useCheckPassward = () => {
  const [flage, setFlage] = useState(false);

  const { isLoading: passwardLoding, mutate: passwardMutate } = useMutation(
    (pass) => {
      let response = APICall("post", EndPoints.passwordVerification, {
        password: pass,
      });
      return response;
    },
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          setFlage(true);
        } else {
          notification.error({ message: data?.data?.message });
          setFlage(false);
        }
      },
    }
  );

  return { flage, passwardLoding, passwardMutate, setFlage };
};

export default useCheckPassward;

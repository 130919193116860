import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import DashboardCommonTable from "../../components/DashboardCommonTable";
import { Col, notification } from "antd";
import VerificationModal from "../../components/VerificationModal/VerificationModal";
import { StyledOverFlowDiv, onlyTime } from "./style";
import {
  findMarket,
  formatNumber,
} from "../../components/FormateToINR/formatToINR";
import { limitNumberLength } from "../../components/SocketLiveData";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { styleTheme } from "./style";

const RejectionLogsTable = React.memo(() => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState({ flag: false, record: null });
  const [loading, setLoading] = useState(false);

  //redux
  const loginAccountType = useSelector(
    (state) => state?.authSlice?.userDetail?.accountType
  );
  const addition = useSelector((state) => state?.authSlice?.additionalSharing);
  const isAllow =
    loginAccountType !== "customer" && loginAccountType !== "broker";

  // life cycle
  useEffect(() => {
    queryClient.resetQueries(["rejectionLogs"]);
  }, []);

  // api call
  const getRejectionLog = async ({ pageParam = 1 }) => {
    let obj = {
      query: {
        childRejectionLogs: {
          page: pageParam,
          limit: 10,
        },
      },
    };

    let response = await APICall(
      "post",
      `${EndPoints.getTradeLog}?isLatest=true`,
      obj
    );
    // console.log("rejectionLogs", response?.data?.data);
    return response?.data?.data;
  };

  const tradeQuery = useInfiniteQuery({
    queryKey: ["rejectionLogs"],
    queryFn: getRejectionLog,
    getNextPageParam: (lastPage) =>
      lastPage?.childRejectionLogsPagination?.nextPage,
  });

  if (tradeQuery?.error) {
    notification.error({ message: tradeQuery?.error?.message });
  }

  const totalData = tradeQuery?.data?.pages?.flatMap(
    (el) => el?.childRejectionLogs
  );

  //function
  const handleExecute = async (record, index) => {
    setLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.exucuteRejectedTrade}${record?._id}`
      );
      if (response?.status === 200) {
        notification.success({ message: response.data?.message });
        // setShowModal({ flag: false, record: null });
        //queryClient.refetchQueries(["rejectionLogs"]);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setShowModal({ flag: false, record: null });
      queryClient.refetchQueries(["rejectionLogs"]);
    }
  };

  const handleCancel = () => {
    setShowModal({ flag: false, record: null });
  };

  // column
  const rejectionColumn = [
    {
      title: "TIME",
      dataIndex: "client",
      key: "time",
      align: "left",
      width: "4rem",
      render: (text, record) => {
        const time = onlyTime(record?.tradeTime);
        return <span>{time}</span>;
      },
    },
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "8rem",
      ellipsis: true,
      render: (text, record) => {
        return (
          <StyledOverFlowDiv
          // title={`${record?.client?.userId} (${record?.client?.accountName})`}
          >{`${record?.client?.userId} (${record?.client?.accountName})`}</StyledOverFlowDiv>
        );
      },
    },
    {
      title: "Market",
      dataIndex: "marketName",
      key: "marketName",
      align: "left",
      width: "5rem",
      ellipsis: true,
      render: (text, record) => {
        return <StyledOverFlowDiv>{record?.marketName}</StyledOverFlowDiv>;
      },
    },
    {
      title: "SCRIPT",
      dataIndex: "symbolName",
      key: "Script",
      width: "14rem",
      align: "left",
      render: (text, record) => {
        const date = valanBillDate(record?.expiryDate, false);
        const opt = record?.marketName?.endsWith("OPT");
        const name = opt
          ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
              record?.strikePrice ? record?.strikePrice : ""
            }`
          : record?.symbolName;
        return (
          <div>
            {name} {date}
          </div>
        );
      },
    },
    {
      title: "ORDER TYPE",
      dataIndex: "ordertype",
      key: "ordertype",
      width: "8rem",
      align: "left",
      render: (text, record) => {
        return <div>{record?.title ? record?.title : "-"}</div>;
      },
    },
    {
      title: "B/S",
      dataIndex: "tradePattern",
      key: "buy/sell",
      width: "4rem",
      align: "left",
    },
    {
      title: "QTY (LOT)",
      dataIndex: "lot",
      key: "lot",
      width: "10rem",
      align: "left",
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        // const intValue = lot?.toFixed(2).replace(/\.?0+$/, "");
        const quantity = formatNumber(record?.quantity);
        // const lot = parseFloat(record?.lot);
        // const intValue = isNaN(lot)
        //   ? ""
        //   : lot?.toFixed(2).replace(/\.?0+$/, "");
        // const quantity = parseFloat(record?.quantity);
        // const intValuequantity = isNaN(quantity) ? "" : parseInt(quantity, 10);
        const data =
          record?.marketName === "NSE EQU" ? quantity : `${quantity} (${lot})`;
        return <div>{data}</div>;
      },
    },
    {
      title: "ORDER PRICE",
      dataIndex: "tradePrice",
      key: "orderPrice",
      width: "8rem",
      align: "left",
      render: (text, record) => {
        const tradePrice = findMarket(record?.marketName)
          ? limitNumberLength(record?.tradePrice)
          : record?.tradePrice?.toFixed(2);
        return <span>{tradePrice}</span>;
      },
    },
    {
      title: "CODE : REJECTION REASON",
      dataIndex: "message",
      key: "message",
      align: "left",
      width: "25rem",
      render: (text, record) => {
        const code = `${record?.message?.code} : ${record?.message?.text}`;
        return <span>{code}</span>;
      },
    },
    {
      title: "USER IP",
      dataIndex: "tradeIpAddress",
      key: "tradeIpAddress",
      align: "left",
      width: "15rem",
      render: (text, record) => {
        return <div>{record?.tradeIpAddress}</div>;
      },
    },
    isAllow && addition?.acceptRejectedTrade
      ? {
          title: "ACTION",
          dataIndex: "action",
          key: "action",
          width: "100px",
          align: "center",
          render: (text, record, index) => {
            return (
              // <Popconfirm
              //   title="Allow the trade"
              //   description="Are you sure to allow this trade?"
              //   onConfirm={() => {
              //     setShowModal({ flag: true, record: record });
              //     // handleExecute(record, index)
              //   }}
              //   // onCancel={cancel}
              //   okText="Yes"
              //   cancelText="No"
              //   placement="bottomRight"
              // >
              <styleTheme.AllowButton
                onClick={() => {
                  setShowModal({ flag: true, record: record });
                  // handleExecute(record, index)
                }}
              >
                Allow
              </styleTheme.AllowButton>
              // </Popconfirm>
            );
          },
        }
      : { width: "0rem" },
  ];

  return (
    <Col span={24}>
      <DashboardCommonTable
        columns={rejectionColumn}
        dataSource={totalData?.[0] ? totalData : []}
        isLoading={tradeQuery.isLoading}
        tableTitle="REJECTION LOGS"
        queryKey="rejectionLogs"
        viewMore={true}
        tradeQuery={tradeQuery}
        emptyPage={
          tradeQuery?.data?.pages?.[0]?.childRejectionLogs?.length === 0
        }
        // allowBtn={true}
      />
      {showModal?.flag && (
        <VerificationModal
          handleCancel={handleCancel}
          record={showModal?.record}
          handleExecute={handleExecute}
          query={"rejectionLogs"}
          type="execute"
          title={"Execute Rejection Trade"}
          executeLoading={loading}
        />
      )}
    </Col>
  );
});

export default RejectionLogsTable;

import React, { useEffect, useRef, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Pagination, Row, notification } from "antd";
import ThemeCardTable from "../../components/ThemeCardTable";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useDispatch, useSelector } from "react-redux";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import { formatToINR } from "../../components/FormateToINR/formatToINR";
import { useQuery } from "@tanstack/react-query";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { StyledButton } from "../../styles/commonStyle";

function ProfitLossReport() {
  //state
  const [totalCounts, setTotalCounts] = useState(0);
  const valueRef = useRef({});
  const dispatch = useDispatch();
  const { params, setSearchParams } = useGetQueryParams();

  //redux
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);
  //life cycle
  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params.userId : loginId,
    });
  }, []);

  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      setSearchParams({ userId: loginId });
    }
  }, [params]);

  // api call
  const getPNLReport = async () => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    const response = await APICall(
      "get",
      `${EndPoints.getPNLReport}userId=${params?.userId}&page=${
        params?.page || 1
      }&limit=${params?.limit || 10}`
    );

    if (response.status === 200) {
      setTotalCounts(response?.data?.data?.totalCount);
      return response?.data?.data;
    } else {
      return [];
    }
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["pnlReport", params],
    queryFn: getPNLReport,
    enabled: Object.keys(params)?.length > 0,
  });

  if (error) {
    notification.error({ message: error.message });
  }

  // function
  const handleFind = () => {
    if (valueRef.current?.userId) {
      const id = valueRef.current.userId;
      setSearchParams({ userId: id });
    } else {
      notification.info({ message: "Please select user" });
    }
  };

  const handleClear = () => {
    if (valueRef.current?.userId) {
      valueRef.current = {};
      dispatch(setUserClearFilter(true));
      setSearchParams({ userId: loginId });
    }
  };

  const handleChange = (value) => {
    setSearchParams({ ...params, page: value });
  };

  // column
  const columns = [
    isLoading
      ? { title: "CLIENT", dataIndex: "user", key: "user", width: "15rem" }
      : {
          title: "CLIENT",
          dataIndex: "user",
          key: "user",
          width: "15rem",
          align: "left",
          render: (text, record) => {
            return <div>{`${record?.userId}(${record?.accountName})`}</div>;
          },
        },
    {
      title: "PERCENTAGE PROFIT",
      dataIndex: "profitLossPercentage",
      key: "profitLossPercentage",
      align: "center",
      width: "25rem",
      render: (text, record) => {
        return (
          !isLoading && <div>{record?.profitLossPercentage?.toFixed(2)}</div>
        );
      },
    },
    {
      title: "MARGIN USED",
      dataIndex: "totalMargin",
      key: "totalMargin",
      align: "center",
      width: "25rem",
      render: (text, record) => {
        return !isLoading && <div>{formatToINR(record?.totalMargin || 0)}</div>;
      },
    },
    {
      title: "NET PNL",
      dataIndex: "netPNL",
      key: "netPNL",
      align: "center",
      width: "25rem",
      render: (text, record) => {
        return !isLoading && <div>{formatToINR(record?.netPNL || 0)}</div>;
      },
    },
  ];

  return (
    <>
      <ThemeCard>
        <Row gutter={[20, 20]}>
          <UserFilterComponant valueRef={valueRef} valan={false} />
        </Row>
        <Row gutter={[10, 10]} style={{ marginTop: "2rem" }}>
          <Col xs={24} sm={12} md={6} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleFind}>
              Search
            </StyledButton>
          </Col>
          <Col xs={24} sm={12} md={6} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleClear}>
              Clear Search
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCardTable
        buttonType={false}
        data={data?.pnlReport}
        column={columns}
        headingStyle={true}
        isFilterTrue={true}
        isLoading={isLoading}
        filterType="pnl"
      />
      <Row align={"center"}>
        <Pagination
          current={params.page || 1}
          total={totalCounts}
          pageSize={params?.limit || 10}
          onChange={handleChange}
          showSizeChanger={false}
        />
      </Row>
    </>
  );
}

export default ProfitLossReport;

import { Tabs } from "antd";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)`
  /* background-color: red; */
  font-size: 16px;
  padding: 16px;
  .ant-tabs-ink-bar {
    background-color: #1677ff;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1677ff !important;
    font-weight: 600 !important;
    font-size: 1.3rem !important;
  }

  .ant-tabs-tab-btn {
    color: #727880;
    font-weight: 500;
    font-size: 1.3rem !important;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  /* Media query for small screens */
  @media only screen and (max-width: 675px) {
    .ant-tabs-nav-wrap {
      justify-content: left;
    }
  }
`;
export const Label = styled.div`
  color: #727880;
  font-weight: 600;
  margin-right: 1rem;
`;
export const tabView = (tab) => {
  if (
    tab === "NSE FUT" ||
    tab === "NSE EQU" ||
    tab === "FOREX" ||
    tab === "GLOBEX"
  ) {
    return true;
  } else {
    return false;
  }
};

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import DashboardCommonTable from "../../components/DashboardCommonTable";
import { Col, notification } from "antd";
import EditTrade from "../../components/EditTrade/EditTrade";
import VerificationModal from "../../components/VerificationModal/VerificationModal";
import {
  DeleteIcon,
  EditIcon,
  RetweetOutIcon,
  StyledOverFlowDiv,
  onlyTime,
} from "./style";
import {
  findMarket,
  formatNumber,
} from "../../components/FormateToINR/formatToINR";
import {
  MemoizedCardCurrentPrice,
  limitNumberLength,
} from "../../components/SocketLiveData";
import { useSocket } from "../../components/Socket";
import store from "../../store/store";
import { setScriptCall } from "../../store/slice/globleSlice";

const currentPrice = (record) => {
  let key = record?.tradePattern === "sell" ? "Ask" : "Bid";
  return key;
};
const PendingTradeTable = React.memo(() => {
  const queryClient = useQueryClient();

  //state
  const [showModal, setShowModal] = useState({
    name: null,
    flag: false,
    record: null,
  });
  const [executeLoading, setExecuteLoading] = useState(false);
  const [symbol, setSymbol] = useState([]);
  const dispatch = useDispatch();
  const socketContext = useSocket();
  const { doSend } = socketContext;

  //redux
  const LoginDetails = useSelector((state) => state?.authSlice?.userDetail);
  const loginUserId = LoginDetails?.userId;
  const loginType = LoginDetails?.accountType;
  const isAllow = loginType !== "broker";
  const isCustomer = loginType === "customer";

  //life cycle
  useEffect(() => {
    queryClient.resetQueries(["pendingTrade"]);

    return () => {
      if (symbol?.length > 0) {
        doSend({
          event: "unsubscribeToServerMarket",
          payload: symbol,
        });
        dispatch(setScriptCall([]));
      }
    };
  }, []);

  // api call
  const pendingQuery = async ({ pageParam = 1 }) => {
    let obj = { userId: loginUserId, tradeStatus: "pending" };
    let response = await APICall(
      "post",
      `${EndPoints.getTrade}?page=${pageParam}&limit=10&isLatest=true`,
      obj
    );
    return response?.data?.data;
  };

  const pendingTrade = useInfiniteQuery({
    queryKey: ["pendingTrade"],
    queryFn: pendingQuery,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  if (pendingTrade?.error) {
    notification.error({ message: pendingTrade?.error?.message });
  }

  const pendingTradeData = pendingTrade?.data?.pages?.flatMap(
    (page) => page?.trades
  );

  useEffect(() => {
    if (
      pendingTrade.data?.pages &&
      !pendingTrade?.isLoading &&
      !pendingTrade?.isFetching
    ) {
      const scriptList = pendingTradeData?.map((el) => el?.symbol) || 0;
      const finalList = [...new Set(scriptList)];
      setSymbol(finalList);
      if (finalList?.length > 0) {
        doSend({
          event: "subscribeToServerMarket",
          payload: finalList,
        });
      }
      dispatch(setScriptCall(finalList));
    }
  }, [pendingTrade?.isLoading, pendingTrade?.isFetching]);

  const deleteTrade = async (record) => {
    const id = record?._id;
    // const customerId = record?.customer?.[0]?._id;
    let obj = {
      deviceType: "web",
      tradeIpAddress: record?.tradeIpAddress,
    };

    try {
      let response = await APICall(
        "delete",
        `${EndPoints.deleteTrade}${id}`,
        obj
      );
      if (response.status === 200) {
        notification.success({ message: response.data.message });
        queryClient.refetchQueries({
          queryKey: "pendingTrade",
          type: "active",
        });
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleExecute = async (record) => {
    const id = record?._id;
    const customerId = record?.customer[0]?.userId;
    const valueType = currentPrice(record);
    const price =
      store.getState()?.webSocketSlice?.webSocket?.data?.[record?.symbol]?.[
        valueType
      ];

    const obj = { tradeStatus: "executed", tradePrice: price };
    setExecuteLoading(true);
    try {
      let response = await APICall(
        "patch",
        `${EndPoints.editTrade}${id}&customerId=${customerId}`,
        obj
      );
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        queryClient.refetchQueries({
          queryKey: ["pendingTrade"],
          type: "active",
        });
        setShowModal({ name: null, flag: false, record: null });
      } else {
        notification.error({ message: response?.data?.message });
      }
      // console.log("response", response);
    } catch (error) {
      console.log("error", error);
    } finally {
      setExecuteLoading(false);
    }
  };

  // function
  const handleCancel = () => {
    setShowModal({ name: null, flag: false, record: null });
  };

  const handleEdit = (record, key) => {
    setShowModal({ name: "edit", flag: true, record: { ...record, key } });
  };

  // column
  const pendingTradeColumns = useMemo(
    () => [
      {
        title: "TIME",
        dataIndex: "time",
        key: "time",
        width: "5rem",
        align: "left",
        render: (text, record) => {
          const time = onlyTime(record?.tradeTime);
          return <span>{time}</span>;
        },
      },
      {
        title: "CLIENT",
        dataIndex: "client",
        width: "8rem",
        key: "client",
        ellipsis: true,
        align: "left",
        render: (text, record) => {
          let id = record?.customer[0]?.userId;
          return (
            <StyledOverFlowDiv>
              {`${id} (${record?.customer[0]?.accountName})`}
            </StyledOverFlowDiv>
          );
        },
      },
      {
        title: "SCRIPT",
        dataIndex: "symbol",
        key: "scriptName",
        width: "8rem",
        align: "left",
        render: (text, record) => {
          const date = record?.expiryDate ? record?.expiryDate : "";
          // const date = valanBillDate(record?.expiryDate, false);
          const opt = record?.marketName?.endsWith("OPT");
          const name = opt
            ? `${record?.symbolName} ${record?.series ? record?.series : ""} ${
                record?.strikePrice ? record?.strikePrice : ""
              }`
            : record?.symbolName;
          return (
            <div>
              {name} {date}
            </div>
          );
        },
      },
      {
        title: "TYPE",
        dataIndex: "tradePattern",
        key: "tradePattern",
        width: "4rem",
        align: "left",
        render: (text, record) => {
          return <span>{record?.title}</span>;
        },
      },
      {
        title: "B/S",
        dataIndex: "tradePattern",
        key: "buy/sell",
        width: "3rem",
        align: "left",
      },
      {
        title: "QTY (LOT)",
        dataIndex: "lot",
        key: "lot",
        width: "7rem",
        align: "left",
        render: (text, record) => {
          const lot = formatNumber(record?.lot);
          // const intValue = lot?.toFixed(2).replace(/\.?0+$/, "");
          const quantity = formatNumber(record?.quantity);
          // const lot = parseFloat(record?.lot);
          // const intValue = isNaN(lot)
          //   ? ""
          //   : lot?.toFixed(2).replace(/\.?0+$/, "");
          // const quantity = parseFloat(record?.quantity);
          // const intValuequantity = isNaN(quantity)
          //   ? ""
          //   : parseInt(quantity, 10);
          const data =
            record?.marketName === "NSE EQU"
              ? quantity
              : `${quantity} (${lot})`;
          return <div>{data}</div>;
        },
      },
      {
        title: "RATE",
        dataIndex: "tradePrice",
        key: "tradePrice",
        width: "5rem",
        align: "center",
        render: (text, record) => {
          const tradePrice = findMarket(record?.marketName)
            ? limitNumberLength(record?.tradePrice)
            : record?.tradePrice?.toFixed(2);
          return <div>{tradePrice}</div>;
        },
      },
      {
        title: "Live",
        dataIndex: "live",
        key: "live",
        align: "center",
        width: "8rem",
        render: (text, record) => {
          return (
            <>
              <MemoizedCardCurrentPrice
                item={record}
                priceType={currentPrice(record)}
              />
            </>
          );
        },
      },
      isAllow
        ? {
            title: "ACTION",
            dataIndex: "edit",
            key: "edit",
            width: "100px",
            align: "center",
            render: (text, record, index) => {
              return (
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <EditIcon
                    onClick={() => handleEdit(record, "pendingTrade")}
                  />
                  {/* <Popconfirm
                    title="delete the trade"
                    description={`Are you sure to delete ${record?.tradePattern} ${record?.symbolName} ${record?.quantity} (${record?.lot}) @ ${record?.tradePrice}   ?`}
                    onConfirm={() => {
                      isCustomer
                        ? deleteTrade(record)
                        : setShowModal({
                            name: "delete",
                            flag: true,
                            record: record,
                          });
                    }}
                    okText="Yes"
                    cancelText="No"
                    placement="bottomRight"
                  > */}
                  <DeleteIcon
                    onClick={() => {
                      isCustomer
                        ? deleteTrade(record)
                        : setShowModal({
                            name: "delete",
                            flag: true,
                            record: record,
                          });
                    }}
                  />
                  {/* </Popconfirm> */}

                  {/* <Popconfirm
                    title="Execute the trade"
                    description="Are you sure to execute this trade?"
                    onConfirm={() =>
                      isCustomer
                        ? handleExecute(record)
                        : setShowModal({
                            name: "execute",
                            flag: true,
                            record: record,
                          })
                    }
                    okText="Yes"
                    cancelText="No"
                    placement="bottomRight"
                  > */}
                  <RetweetOutIcon
                    onClick={() =>
                      isCustomer
                        ? handleExecute(record)
                        : setShowModal({
                            name: "execute",
                            flag: true,
                            record: record,
                          })
                    }
                  />
                  {/* </Popconfirm> */}
                </div>
              );
            },
          }
        : {
            width: "0px",
          },
    ],
    [handleExecute, pendingTradeData]
  );

  return (
    <Col xxl={12} xl={12} lg={24}>
      <DashboardCommonTable
        columns={pendingTradeColumns}
        tableTitle="PENDING ORDERS"
        dataSource={pendingTradeData?.[0] ? pendingTradeData : []}
        viewMore={true}
        allowBtn={false}
        queryKey="pendingTrade"
        isLoading={pendingTrade?.isLoading}
        tradeQuery={pendingTrade}
        emptyPage={pendingTrade?.data?.pages?.[0]?.trades?.length === 0}
      />
      {showModal?.flag && showModal?.name === "edit" && (
        <EditTrade handleCancel={handleCancel} record={showModal?.record} />
      )}

      {showModal?.flag && showModal?.name === "delete" && (
        <VerificationModal
          handleCancel={handleCancel}
          record={showModal?.record}
          query={"pendingTrade"}
          type="delete"
          title={"Delete Trade"}
        />
      )}
      {showModal?.flag && showModal?.name === "execute" && (
        <VerificationModal
          handleCancel={handleCancel}
          record={showModal?.record}
          handleExecute={handleExecute}
          executeLoading={executeLoading}
          query={"pendingTrade"}
          type="execute"
          title={"Execute Pending Trade"}
        />
      )}
    </Col>
  );
});

export default PendingTradeTable;

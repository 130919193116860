import React, { useEffect } from "react";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useSelector } from "react-redux";
import { FilePdfOutlined } from "@ant-design/icons";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { notification } from "antd";

function AccountPointLedger() {
  const loginId = useSelector((state) => state.authSlice?.userDetail?.userId);
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.refetchQueries(["getLedger"]);
  }, []);

  // api call
  const getLegder = async () => {
    let response = await APICall(
      "get",
      `${EndPoints.getLedger}?clientId=${loginId}`
    );
    return response?.data?.data?.entries;
  };

  const {
    isLoading,
    error: PointLedgerError,
    data,
  } = useQuery({
    queryKey: ["getLedger"],
    queryFn: getLegder,
  });

  if (PointLedgerError) {
    notification.error({ message: PointLedgerError?.message });
  }

  //column
  const columns = [
    isLoading
      ? {
          title: "Sr No.",
          dataIndex: "date",
          key: "date",
          width: "5rem",
          align: "center",
        }
      : {
          title: "Sr No.",
          dataIndex: "date",
          key: "date",
          width: "5rem",
          align: "center",
          style: { marginLeft: "1rem" },
          render: (text, record, index) => {
            return <span style={{ marginLeft: "1rem" }}>{index + 1}</span>;
          },
        },
    {
      title: "NAME",
      dataIndex: "remark",
      key: "remark",
      align: "center",
      render: (text, record) => {
        return (
          !isLoading && (
            <div>{`${record?.userId} (${record?.accountName})`}</div>
          )
        );
      },
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (text, record) => {
        const finalDate = valanBillDate(record?.date, true);
        return !isLoading && <span>{finalDate}</span>;
      },
    },
    {
      title: "DEBIT",
      dataIndex: "debit",
      key: "debit",
      align: "center",
      render: (text, record) => {
        if (record?.entryType === "debit") {
          return !isLoading && <span>{record?.amount}</span>;
        } else {
          return !isLoading && "-";
        }
      },
    },
    {
      title: "CREDIT",
      dataIndex: "credit",
      key: "credit",
      align: "center",
      render: (text, record) => {
        if (record?.entryType === "credit") {
          return !isLoading && <span>{record?.amount}</span>;
        } else {
          return !isLoading && "-";
        }
      },
    },
    {
      title: "BALANCE",
      dataIndex: "ledgerBalance",
      key: "ledgerBalance",
      align: "center",
    },
    {
      title: "BILL",
      dataIndex: "bill",
      key: "bill",
      align: "center",
      render: (text, record) => {
        return (
          !isLoading && (
            <FilePdfOutlined
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          )
        );
      },
    },
  ];

  return (
    <>
      <ThemeCardTable
        buttonType={false}
        data={data ? data : []}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={isLoading}
      />
    </>
  );
}

export default AccountPointLedger;

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Button, Col, Row, Table } from "antd";
import styled from "styled-components";
import {
  showValue,
  valanBillDate,
} from "../../components/CommanFuction/CommanFunction";
import {
  formatForValanBill,
  formatNumber,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";

export const buttonTitles = [
  { label: "CSV", value: "csv" },
  { label: "PDF", value: "pdf" },
];
export const ResetButton = styled(Button)`
  border-radius: ${(props) => props.theme.token.borderRadiusLG};
  font-size: 1rem;
  width: 100%;
  box-shadow: ${(props) => props.theme.token.boxShadowNone};
  height: 3rem;
  @media (max-width: 1079px) {
    height: 2.5rem;
    font-size: 0.8rem;
  }
`;
export const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: "center";
  /* border: 1px solid red; */
`;
export const ScriptNameRow = styled(Row)`
  background-color: ${(props) => props.theme.token.colorPrimary};
  color: white;
  font-size: 1.5rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
`;
export const ColorSpan = styled.span`
  color: ${(props) => props.color};
`;
export const MarketFooterRow = styled(Row)`
  background-color: white;
  font-weight: 400;
`;
export const MarketNameCol = styled(Col)`
  font-size: 2rem;
  font-weight: 500;
`;
export const HeadingRow = styled(Row)`
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: ${(props) => (props?.loading ? "none" : "flex")};
`;
export const PdfRow = styled(Row)`
  font-weight: 500;
  margin-bottom: 1rem;
`;
export const PdfDiv = styled.div`
  font-size: 1.3rem;
`;
export const accountTypeObj = {
  owner: "OW",
  masterAdmin: "MA",
  superAdmin: "SA",
  admin: "AD",
  master: "M",
  dealer: "DE",
  customer: "CU",
  broker: "BR",
};
export const styles = StyleSheet.create({
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
  },
  tableCell: {
    flex: 1,
    padding: 5,
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  userInfoCell: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tag: {
    backgroundColor: "#800080",
    color: "#fff",
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
    fontSize: 10,
  },
  pdfLink: {
    color: "#0000FF",
    textDecoration: "underline",
  },
});
export const StyledTheme = {
  Root: styled.div`
    ${"" /* width: 100%; */}
    height: fit-content;
    background-color: ${(props) => props.theme.token.colorPrimary};
    /* border-radius: ${(props) => props.theme.token.borderRadiusLG}px; */
    margin-bottom: 1.5rem;
  `,
  Table: styled(Table)`
    transform: none !important;
    & .ant-table-thead tr .ant-table-cell {
      background-color: ${(props) => props.theme.token.colorPrimary};
      color: ${(props) => props.theme.token.colorTextLightSolid};
      font-size: 1.2;
      font-weight: 300;
    }
    @media screen and (max-width: 1079px) {
      .ant-table-thead tr .ant-table-cell {
        font-size: 0.8rem;
      }
      .ant-table-body .ant-table-cell {
        font-size: 0.95rem;
      }
    }
    @media screen and (max-width: 991px) {
      .ant-table-thead tr .ant-table-cell {
        font-size: 0.85rem;
      }
      .ant-table-body .ant-table-cell {
        font-size: 0.85rem;
      }
    }

    .ant-table-thead tr .ant-table-cell::before {
      display: none;
    }

    .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
      border-top: ${(props) =>
        `1px solid ${props.theme.token.colorBorder} !important`};
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
      /* padding: 0.5rem !important; */
    }
    .ant-table-thead tr th {
      height: 2rem !important;
      padding: 0rem 0.5rem !important;
    }
    .collapseTable .ant-collapse-expand-icon span svg {
      fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
    }
    .collapseTable .ant-collapse-header-text {
      color: ${(props) => props.theme.token.colorTextLightSolid};
    }

    .ant-switch-checked .ant-switch-inner {
      background-color: ${(props) =>
        props.theme.token.colorBgActionButton} !important;
    }

    .ant-table-thead {
      height: 3.18rem;
    }
    .ant-table-thead .ant-table-cell {
      &:hover {
        background: ${(props) =>
          props.theme.token.colorPrimaryBgHover} !important;
      }
    }
    .ant-table-body .ant-table-cell {
      padding: 0.5rem !important;
    }
  `,
  ThemeColumn: styled(Col)`
    font-size: 1.4rem;
  `,
  ThemeBuyColumn: styled(Col)`
    font-size: 1.4rem;
    width: 50%;
    background-color: lightgreen;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    padding-left: 0.5rem;
  `,
  ThemeSellColumn: styled(Col)`
    font-size: 1.4rem;
    width: 50%;
    background-color: lightpink;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    padding-right: 0.5rem;
    text-align: end;
  `,
  TitleRow: styled(Row)`
    color: black;
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  `,
};
export const FooterRow = styled.tr`
  background-color: #edecfa;
  height: 8px;
  width: 100%;
  line-height: 1px;
  font-weight: 500;
  font-size: small;
  border-bottom-left-radius: 10px;
`;
export const PDFButton = styled(Button)`
  border-radius: ${(props) => props.theme.token.borderRadiusLG};
  font-size: 1rem;
  background-color: ${(props) => props.theme.token.colorPrimary};
  box-shadow: ${(props) => props.theme.token.boxShadowNone};
  margin-right: 2rem;
  color: white;
  height: 3rem;
  :hover {
    color: white;
  }
  @media (max-width: 1079px) {
    height: 2.5rem;
    font-size: 0.8rem;
  }
`;
export const stylesValanPDF = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  buySellRow: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 10,
    backgroundColor: "lightgreen",
  },
  column: {
    flexDirection: "column",
    fontWeight: "bold",
  },
  themeColumn: {
    flexDirection: "row",
    alignItems: "center",
  },
  themeBuyColumn: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "lightgreen",
    width: "50%",
    padding: "3px",
  },
  themeSellColumn: {
    flexDirection: "row",
    backgroundColor: "lightpink",
    textAlign: "right",
    width: "50%",
    padding: "3px",
  },
  titleRow: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 5,
  },
  themeCard: {
    marginBottom: 20,
  },
  scriptTitle: {
    fontSize: 16,
    fontWeight: "bold",
  },
  segmentBill: {
    fontSize: 12,
    textAlign: "right",
    display: "flex",
    // marginRight: "1rem",
  },
  segmentBillAmount: {
    color: "green",
  },
  scriptHeader: {
    backgroundColor: "#0072bc",
    color: "white",
    fontSize: 12,
    padding: 5,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    textAlign: "center",
  },
  table: {
    marginBottom: 0,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderLeftWidth: 1, // Add this line
    borderLeftColor: "#000", // Add this line
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
  },
  tableCell: {
    flex: 1,
    paddingVertical: 4,
    paddingHorizontal: 5,
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderRightStyle: "solid",
    fontSize: "12px",
    textAlign: "center",
  },
  button: {
    backgroundColor: `${(props) => props.theme.token.colorPrimaryBgHover}`,
  },
  rightAlignedCell: {
    flex: 1,
    padding: 5,
    // textAlign: "right",
    textAlign: "center",
  },
  footerContainer: {
    borderTopWidth: 0,
    // borderTopColor: "#000",
    borderTopStyle: "solid",
    textAlign: "center",
    marginBottom: "10px",
    backgroundColor: "#edecfa",
  },
  footerRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderLeftWidth: 1, // Add this line
    borderLeftColor: "#000", // Add this line
    // textAlign: "center",
  },
  emptyCellStyle: {
    flex: 1,
    padding: 5,
    textAlign: "center",
    fontSize: 10,
    fontFamily: "Helvetica",
    color: "transparent",
  },
  scriptBottom: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "10px",
  },
});

export const columnsPdf = [
  {
    title: "SR NO.",
    dataIndex: "srNo",
    key: "srNo",
    align: "center",
    width: "4rem",
    render: (_text, _record, index) => (
      <Text style={styles.tableCell}>{index + 1}</Text>
    ),
  },
  {
    title: "USER INFO",
    dataIndex: "userId",
    key: "userId",
    align: "center",
    render: (text, record) => (
      <View style={styles.userInfoCell}>
        <Text
          style={styles.tableCell}
        >{`${record?.userId} (${record?.AccountName})`}</Text>
        <Text style={styles.tag}>{accountTypeObj[record?.AccountType]}</Text>
      </View>
    ),
  },
  {
    title: "VALAN",
    dataIndex: "valan",
    key: "valan",
    align: "center",
    render: (text, record) => (
      <Text style={styles.tableCell}>{record?.valan?.valanName}</Text>
    ),
  },
  {
    title: "TOTAL VALAN BILL",
    dataIndex: "totalValanBill",
    key: "totalValanBill",
    align: "center",
    render: (text, record) => (
      <View style={styles.tableCell}>
        <Text>{showValue(record?.totalNetAmount)}</Text>
        {/* <Link style={styles.pdfLink}>
          <FilePdfOutlined />
        </Link> */}
      </View>
    ),
  },

  {
    title: "WITHOUT BROKERAGE",
    dataIndex: "withoutBrokerage",
    key: "withoutBrokerage",
    align: "center",
    render: (text, record) => {
      let withourBrokerage = showValue(record?.withoutBrokerage);
      <View style={styles.tableCell}>
        <Text>{withourBrokerage}</Text>
      </View>;
    },
  },
  {
    title: "BROKERAGE",
    dataIndex: "brokerage",
    key: "brokerage",
    align: "center",
    render: (text, record) => (
      <Text style={styles.tableCell}>{record?.brokerage?.toFixed(2)}</Text>
    ),
  },
  {
    title: "DOWNLINE BILL",
    dataIndex: "downlinem2m",
    key: "downlinem2m",
    align: "center",
    render: (text, record) => (
      <Text style={styles.tableCell}>{record?.downlinem2m?.toFixed(2)}</Text>
    ),
  },
  {
    title: "UPLINE BILL",
    dataIndex: "uplinem2m",
    key: "uplinem2m",
    align: "center",
    render: (text, record) => (
      <Text style={styles.tableCell}>{record?.uplinem2m?.toFixed(2)}</Text>
    ),
  },
  {
    title: "SELF BILL",
    dataIndex: "selfm2m",
    key: "selfm2m",
    align: "center",
    render: (text, record) => (
      <Text style={styles.tableCell}>{record?.selfm2m?.toFixed(2)}</Text>
    ),
  },
];
export const valanBillColumn = [
  {
    title: "Sr No.",
    dataIndex: "number",
    width: "2rem",
    render: (_text, record, index) => {
      let number = index + 1;
      if (record?.customerId) {
        return <Text style={stylesValanPDF.tableCell}>{number}</Text>;
      }
    },
  },
  {
    title: "Client",
    dataIndex: "client",
    width: "5rem",
    render: (_text, record) => {
      if (record?.customerId) {
        return (
          <Text
            style={stylesValanPDF.tableCell}
          >{`${record?.customerId?.userId}-${record?.customerId?.accountName}`}</Text>
        );
      }
    },
  },
  {
    title: "Date",
    dataIndex: "tradeTime",
    width: "8rem",
    render: (text, record) => {
      if (record?.tradeTime) {
        let time = valanBillDate(record?.tradeTime, true);
        return <Text style={stylesValanPDF.tableCell}>{time}</Text>;
      }
    },
  },
  {
    title: "Type",
    dataIndex: "title",
    width: "3rem",
    render: (text, record) => {
      return (
        <Text
          style={[
            stylesValanPDF.tableCell,
            {
              color: record?.title === "live" ? "green" : "black",
              fontWeight: "500",
            },
          ]}
        >
          {record?.title}
        </Text>
      );
    },
  },
  {
    title: "Lot",
    dataIndex: "lot",
    width: "3rem",
    render: (text, record) => {
      const lot = formatNumber(record?.lot);
      return (
        <Text style={stylesValanPDF.tableCell}>
          {record?.title !== "live" ? lot : ""}
        </Text>
      );
    },
  },
  {
    title: "Buy Qty",
    dataIndex: "buyQty",
    width: "3rem",
    render: (text, record) => {
      let buy = "-";
      if (record.tradePattern === "buy") {
        buy = record.quantity;
      }
      return (
        <Text
          style={[
            stylesValanPDF.tableCell,
            { color: buy !== "-" ? "green" : "black", fontWeight: "600" },
          ]}
        >
          {formatToINR(buy)}
        </Text>
      );
    },
  },
  {
    title: "Sell Qty",
    dataIndex: "SellQty",
    width: "3rem",
    render: (text, record) => {
      let sell = "-";
      if (record.tradePattern === "sell") {
        sell = record.quantity;
      }
      return (
        <Text
          style={[
            stylesValanPDF.tableCell,
            { color: sell !== "-" ? "red" : "black", fontWeight: "500" },
          ]}
        >
          {formatToINR(sell)}
        </Text>
      );
    },
  },
  {
    title: "Rate",
    dataIndex: "tradePrice",
    width: "4rem",
    render: (text, record) => {
      if (record?.tradePrice) {
        const formattedPrice =
          record?.marketName === "FOREX"
            ? record?.tradePrice
            : record?.tradePrice;
        return (
          <Text style={stylesValanPDF.rightAlignedCell}>
            {formatForValanBill(formattedPrice)}
          </Text>
        );
      }
    },
  },
  {
    title: "Net Rate",
    dataIndex: "netRate",
    width: "4rem",
    render: (text, record) => {
      if (record?.netRate) {
        const netRate = record?.netRate;
        return (
          <Text style={stylesValanPDF.rightAlignedCell}>
            {formatForValanBill(netRate)}
          </Text>
        );
      }
    },
  },
  {
    title: "Net Amount",
    dataIndex: "netAmount",
    width: "4rem",
    render: (text, record) => {
      if (record?.netAmount) {
        const netAmount = record?.netAmount;
        return (
          <Text style={stylesValanPDF.rightAlignedCell}>
            {formatForValanBill(netAmount)}
          </Text>
        );
      }
    },
  },
];

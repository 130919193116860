import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setExprirySetting } from "../../store/slice/expirySettingSlice";
import { Label } from "./style";
import { optionDays, options } from "../../Helper/constant";
import ThemeDropDown from "../../components/ThemeDropDown";
import ThemeCard from "../../components/ThemeCard";

const NseFutureRender = ({ tab }) => {
  const dispatch = useDispatch();

  // redux
  const expiryValue = useSelector((state) => state.expirySettingSlice[tab]);

  // function
  const handleChange = (name, selectedValue, selectedOptions) => {
    if (name === "squareUpRate") {
      dispatch(
        setExprirySetting({
          key: "squareUpRate",
          value: selectedValue[0],
          tab: tab,
          parent_key: "intraday",
        })
      );
    } else if (name === "rate" || name === "dayBefore") {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
          nested_key: "expirySquareUpRateAndDayBefore",
        })
      );
    } else if (
      name === "valanEndingSquareUpRate" ||
      name === "newPositionStartBeforeDay"
    ) {
      dispatch(
        setExprirySetting({
          key: [name],
          value: selectedValue[0],
          tab: tab,
          parent_key: "position",
        })
      );
    } else {
      setExprirySetting({
        key: [name],
        value: selectedValue[0],
        tab: tab,
      });
    }
  };

  return (
    <>
      <ThemeCard
        title="INTRADAY"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.3rem",
          }}
        >
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Label>Intraday Square-Up Rate</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="squareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue?.intraday?.squareUpRate}
            />
          </Col>
        </Row>
      </ThemeCard>
      <ThemeCard
        title="POSITION"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "2rem",
        }}
      >
        <Row
          gutter={[10, 10]}
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>Expiry Square-Up Rate And Day Before</Label>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Quotation Rate"
              name="rate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={
                expiryValue?.position?.expirySquareUpRateAndDayBefore?.rate
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="dayBefore"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={
                expiryValue?.position?.expirySquareUpRateAndDayBefore?.dayBefore
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xl={5}>
            <Label>Valan Ending Square-Up Rate </Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Closing Rate"
              name="valanEndingSquareUpRate"
              width={"98%"}
              options={options.user.Expiry.MCX_OPTION}
              $onChange={handleChange}
              $value={expiryValue?.position?.valanEndingSquareUpRate}
            />
          </Col>
        </Row>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
            fontSize: "1.1rem",
          }}
        >
          <Col xs={24} sm={24} md={12} lg={10} xl={6}>
            <Label>New Position Start Before Day</Label>
          </Col>
          <Col xs={24} sm={12} md={6} lg={5} xl={4}>
            <ThemeDropDown
              placeholder="Select Days"
              name="newPositionStartBeforeDay"
              width={"98%"}
              options={optionDays.slice(0, 31)}
              $onChange={handleChange}
              $value={expiryValue?.position?.newPositionStartBeforeDay}
            />
          </Col>
        </Row>
      </ThemeCard>
    </>
  );
};

export default NseFutureRender;
